import React, { useEffect, useRef, useState } from 'react';
import Navbar from '../Navbar';
import Typography from '@mui/material/Typography';
import { Avatar, Box, Button, Card, CardContent, CardMedia, Dialog, Grid, Rating, Toolbar, Paper } from '@mui/material';
import wedding from '../../assets/icons/wedding.webp'
import { Description, ThumbUp, HeadsetMic } from '@mui/icons-material';
import cards from '../../assets/icons/cards.jpg';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllCardSuggestionData } from '../../redux/actions/getAllCardSuggestionActions'
import GooglePlay from '../../../src/assets/icons/GooglePlay.png';
import appleStore from '../../../src/assets/icons/appleStore.jpg';
import { LinearProgress } from '@mui/material';

import engagement from '../../assets/main-icon/engagement.png'
import wedding1 from '../../assets/main-icon/wedding1.png';
import biodata from '../../assets/main-icon/biodata.png';
import houseWarming from '../../assets/main-icon/houseWarming.png';
import haldi from '../../assets/main-icon/haldi.png';
import sangeet from '../../assets/main-icon/Sangeet.png';
import MuslimWedding from '../../assets/main-icon/MuslimWedding.png';
import Mundan from '../../assets/main-icon/Mundan.png';
import Birthday from '../../assets/main-icon/Birthday.png';
import Annaprashan from '../../assets/main-icon/Annaprashan.png'
import Anniversary from '../../assets/main-icon/Anniversary.png'




const categories = [
  { label: 'Engagement Card', img: engagement, path: 'engagement' },
  { label: 'Marriage Invitation', img: wedding1, path: 'wedding' },
  { label: 'Marriage Biodata', img: biodata, path: 'biodata' },
  { label: 'House Warming Card', img: houseWarming, path: 'housewarming' },
  { label: 'Haldi Invitation', img: haldi, path: 'haldi' },
  { label: 'Sangeet Invitation', img: sangeet, path: 'sangeet' },
  { label: 'Muslim Wedding Cards', img: MuslimWedding, path: 'muslim-wedding' },
  { label: 'Mundan Card', img: Mundan, path: 'mundan' },
  { label: 'Birthday Invitation', img: Birthday, path: 'birthday-invitation-card' },
  { label: 'Annaprashan Invitation', img: Annaprashan, path: 'annaprashan-card' },
  { label: 'Anniversary Invitation', img: Anniversary, path: 'anniversary' },
];

const invitations = [
  { title: 'Serenity ', image: cards, rating: 5 },
  { title: 'Bird of Charm', image: cards, rating: 5 },
  { title: 'Loving Paradise', image: cards, rating: 5 },
  { title: 'Dastaan', image: cards, rating: 5 },
  { title: 'Sweet William', image: cards, rating: 5 },
  { title: 'Serenity ', image: cards, rating: 5 },
  { title: 'Bird of Charm', image: cards, rating: 5 },
  { title: 'Loving Paradise', image: cards, rating: 5 },
  { title: 'Dastaan', image: cards, rating: 5 },
  { title: 'Sweet William', image: cards, rating: 5 },
];

const steps = [
  {
    number: 1,
    title: 'Choose Digital Invitation',
  },
  {
    number: 2,
    title: 'Fill Details',
  },
  {
    number: 3,
    title: 'Payment & download instantly',
  },
];


const HomePage = () => {
  const textBoxRef = useRef(null);
  const [textBoxHeight, setTextBoxHeight] = useState(0);

  useEffect(() => {
    if (textBoxRef.current) {
      setTextBoxHeight(textBoxRef.current.offsetHeight);
    }
  }, []);
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  // fetch card suggestion data 
  const params = useParams();
  console.log("params", params);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 50;

  const cardSuggestion = useSelector((state) => state?.
    getAllCardSuggestions?.getAllCardSuggestion?.data);

  console.log("Card suggestion in home page", cardSuggestion);

  const engagementCardSuggestions = useSelector((state) => state?.getAllCardSuggestions?.engagement?.getAllCardSuggestion?.data);
  const birthdayCardSuggestions = useSelector((state) => state?.getAllCardSuggestions?.birthday?.getAllCardSuggestion?.data);
  const weddingCardSuggestions = useSelector((state) => state?.getAllCardSuggestions?.wedding?.getAllCardSuggestion?.data);

  console.log("Data according group_path", engagementCardSuggestions);


  const [loading, setLoading] = useState(false);
  useEffect(() => {

    const fetchAllCardSuggestion = async () => {
      setLoading(true);
      await dispatch(getAllCardSuggestionData("engagement", "pdf", page, limit));
      await dispatch(getAllCardSuggestionData("wedding", "pdf", page, limit));
      await dispatch(getAllCardSuggestionData("birthday", "pdf", page, limit));
      setLoading(false);
    };
    fetchAllCardSuggestion();

  }, [dispatch]);






  //display cards img

  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedimage] = useState(null);


  const handleClickOpen = (img) => {
    setSelectedimage(img);
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
    setSelectedimage(null);
  }

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
    window.scrollTo(0, 0);
  }, []);

  const handleNavigation = (path_url) => {
    navigate(`/cards/${path_url}`);
  };

  return (
    <div>
      {loading ? (
        <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
          <Typography>Welcome to RajOffset...</Typography>
          <LinearProgress />

        </Box>
      ) : (
        <>
          <Box
            sx={{
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '15px',
            }}
          >
            <img
              src="https://innovators.blr1.cdn.digitaloceanspaces.com/static/rajoffset_dev_diwali_sale_2.jpg"
              alt="Wedding"
              style={{ width: '100%', maxWidth: '1000px' }}
              onClick={() => navigate('/bundle-page')}
            />
          </Box>
          <Box sx={{ backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.12)' : '#f5f5f5', padding: '20px 0' }}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">

              {/* Unique Biodata Design */}
              <Grid item xs={12} sm={4}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  <Description sx={{ fontSize: 50, color: isDarkMode ? '#B3B3B3' : '#F44336', marginRight: '10px' }} />
                  <Box sx={{ textAlign: 'left' }}>
                    <Typography sx={{ fontWeight: 'bold', color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'black', fontSize: { xs: '1rem', md: '1.2rem' } }}>
                      Unique Design at Best Price
                    </Typography>
                    <Typography variant="body2" sx={{ maxWidth: '250px', color: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : '#757575', }}>
                      We provide Best Digital Invitations with an impressive and beautiful design exclusively on RajOffset Platform.
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              {/* Lowest Price Guarantee */}
              <Grid item xs={12} sm={4}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  <ThumbUp sx={{ fontSize: 50, color: isDarkMode ? '#B3B3B3' : '#F44336', marginRight: '10px' }} />
                  <Box sx={{ textAlign: 'left' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'black', fontSize: { xs: '1rem', md: '1.2rem' } }}>
                      Lowest Price Guarantee
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ maxWidth: '250px', color: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : '#757575' }}>
                      We provides the best quality product at the cheapest price. Customer's satisfaction is our main priority.
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              {/* 24/7 Support */}
              <Grid item xs={12} sm={4}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  <HeadsetMic sx={{ fontSize: 50, color: isDarkMode ? '#B3B3B3' : '#F44336', marginRight: '10px' }} />
                  <Box sx={{ textAlign: 'left' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'black', fontSize: { xs: '1rem', md: '1.2rem' } }}>
                      24/7 WhatsApp Support
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ maxWidth: '250px', color: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : '#757575' }}>
                      Our Support team is available 24/7 to support any issues or suggestions. Connect with us on WhatsApp to resolve your issue anytime.
                    </Typography>
                  </Box>
                </Box>
              </Grid>

            </Grid>
          </Box>


          <Box sx={{ padding: '20px 0' }}>
            <Typography
              color="initial"
              sx={{
                textAlign: 'center',
                marginBottom: 2,
                color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'black',
                fontSize: { xs: '1.8rem', md: '2rem' }
              }}
            >
              Top Category
            </Typography>
            <Grid container spacing={2} justifyContent="center" sx={{ width: '90%', marginX: 'auto' }}>
              {categories.map((category, index) => (
                <Grid item xs={6} sm={4} md={3} lg={1.5} key={index} textAlign="center">
                  {/* <Card sx={{ maxWidth: 150, maxHeight: 'full', margin: 'auto' }}>
                <CardMedia
                  component="img"
                  height="100"
                  image={category.img}
                  alt={category.label}
                  sx={{ objectFit: 'cover', borderRadius: 1 }}
                  onClick={() => handleClickOpen(category.img)}
                />
              </Card> */}
                  <img
                    src={category.img}
                    alt={category.label}
                    style={{ maxWidth: 150, maxHeight: 'full', margin: 'auto' }}
                    onClick={() => handleNavigation(category.path)}
                  />
                  <Typography
                    variant="body1"
                    sx={{
                      marginTop: '10px',
                      color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'black',
                    }}
                  >
                    {category.label}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>


          <Box p={3}>
            <Typography variant="h4" align="center" gutterBottom sx={{ color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'black', fontSize: { xs: '1.8rem', md: '2rem' } }}>
              Engagement Invitation Cards
            </Typography>
            <Grid container spacing={3}>
              {engagementCardSuggestions?.rows?.map((card, index) => (
                <Grid item xs={12} sm={6} md={4} lg={2.4} key={index}>
                  {card?.card_samples?.map((img, id) => (
                    <Card key={id} onClick={() => navigate(`/cards/engagement/${card.id}`)} >
                      <CardMedia
                        component="img"
                        alt={img.thumbnail}
                        height="240"
                        image={img.url}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="body1" component="div">
                          {card.card_name}
                        </Typography>
                        <Rating value={card.card_rating} readOnly />
                      </CardContent>
                    </Card>
                  ))}

                </Grid>
              ))}
            </Grid>
            <Box display="flex" justifyContent="center" mt={4}>
              <Button onClick={() => navigate(`/cards/engagement`)} variant="contained" color="secondary" sx={{ textTransform: 'none', backgroundColor: isDarkMode ? '#BB86FC' : '#ef5350', borderEndEndRadius: 3 }}>
                Browse More Engagement Invites
              </Button>
            </Box>
          </Box>

          <Box p={3}>
            <Typography variant="h4" align="center" gutterBottom sx={{ color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'black', fontSize: { xs: '1.8rem', md: '2rem' } }}>
              Wedding Invitation Cards
            </Typography>
            <Grid container spacing={3}>
              {weddingCardSuggestions?.rows?.map((card, index) => (
                <Grid item xs={12} sm={6} md={4} lg={2.4} key={index}>
                  {card?.card_samples?.map((img, id) => (
                    <Card key={id} onClick={() => navigate(`/cards/engagement/${card.id}`)}>
                      <CardMedia
                        component="img"
                        alt={img.thumbnail}
                        height="240"
                        image={img.url}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="body1" component="div">
                          {card.card_name}
                        </Typography>
                        <Rating value={card.card_rating} readOnly />
                      </CardContent>
                    </Card>
                  ))}

                </Grid>
              ))}
            </Grid>
            <Box display="flex" justifyContent="center" mt={4}>
              <Button onClick={() => navigate(`/cards/wedding`)} variant="contained" color="secondary" sx={{ textTransform: 'none', backgroundColor: isDarkMode ? '#BB86FC' : '#ef5350', borderEndEndRadius: 3 }}>
                Browse More Wedding Invites
              </Button>
            </Box>
          </Box>

          <Box p={3}>
            <Typography variant="h4" align="center" gutterBottom sx={{ color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'black', fontSize: { xs: '1.8rem', md: '2rem' } }}>
              Birthday Invitation Cards
            </Typography>
            <Grid container spacing={3}>
              {birthdayCardSuggestions?.rows?.map((card, index) => (
                <Grid item xs={12} sm={6} md={4} lg={2.4} key={index}>
                  {card?.card_samples?.map((img, id) => (
                    <Card key={id} onClick={() => navigate(`/cards/birthday/${card.id}`)}>
                      <CardMedia
                        component="img"
                        alt={img.thumbnail}
                        height="240"
                        image={img.url}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="body1" component="div">
                          {card.card_name}
                        </Typography>
                        <Rating value={card.card_rating} readOnly />
                      </CardContent>
                    </Card>
                  ))}

                </Grid>
              ))}
            </Grid>
            <Box display="flex" justifyContent="center" mt={4}>
              <Button onClick={() => navigate(`/cards/birthday`)} variant="contained" color="secondary" sx={{ textTransform: 'none', backgroundColor: isDarkMode ? '#BB86FC' : '#ef5350', borderEndEndRadius: 3 }}>
                Browse More Engagement Invites
              </Button>
            </Box>
          </Box>
        </>
      )}



      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <img src={selectedImage} alt="Selected" style={{ width: "100%", height: "auto" }} />
      </Dialog>


      {/* Stats Section */}
      <Box
        sx={{
          backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.12)' : '#4a7856',
          color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'white',
          padding: 2,
          // Added border for visibility in dark mode
        }}
      >
        <Grid container justifyContent="space-around">
          {['10000+', '1000+', '5000+', '20+'].map((stat, index) => (
            <Grid item key={index}>
              <Typography variant="h4"
                sx={{
                  fontSize: { xs: '35px' }
                }}>
                {stat}
              </Typography>
              <Typography variant="body1" sx={{}}>
                {['Active Visitor', 'App Downloads', 'Digital Invitations', 'User by State'][index]}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Testimonials Section */}
      <Box
        sx={{
          backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.3)' : '#d4e4ed',
          padding: 4,
          textAlign: 'center',
          // Added border for visibility in dark mode
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ color: isDarkMode ? 'black' : 'black' }}>
          What Customers say about Us
        </Typography>
        <Grid container justifyContent="center" spacing={3}>
          {[
            { text: '"Local for vocal!"', msg: 'Very Easy to use for bussinessman too. Local for vocal Proudly Made in Bharat🇮🇳', author: 'Abhishek Singh' },
            { text: '"Very easy and nice"', msg: 'Very easy to use nic aap made in India for business & family celebration', author: 'Akash Singh' },
            { text: '"Very Good App"', msg: 'Very good app .', author: 'Ankit Mishra' },
            { text: '"Nice app"', msg: 'Nice app .', author: 'Sahil Patel' }
          ].map((testimonial, index) => (
            <Grid item key={index}>
              <Box>
                <Paper
                  elevation={3}
                  sx={{
                    padding: 2,
                    backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.3)' : '#f7e6e0',
                    maxWidth: 300,

                  }}
                >
                  <Typography variant="body1" sx={{ textAlign: 'left', fontWeight: 800, color: isDarkMode ? 'black' : 'black' }}>
                    {testimonial.text}
                  </Typography>
                  <Typography variant="body1" sx={{ textAlign: 'left', fontSize: '0.925rem', color: isDarkMode ? 'black' : 'black' }}>
                    {testimonial.msg}
                  </Typography>
                </Paper>
                <Typography variant="caption" sx={{ display: 'block', marginTop: 1, textAlign: 'right', color: isDarkMode ? 'black' : 'black' }}>
                  - {testimonial.author}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* App Download Section */}
      <Box
        sx={{
          backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.12)' : '#f7d5cc',
          textAlign: 'center',
          padding: 2,
        }}
      >
        <Typography variant='h6'>Download our mobile app from</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <Typography
            variant="h6"
            noWrap
            component="div" sx={{ paddingLeft: 1, color: isDarkMode ? 'white' : '#003366', fontWeight: 'bold' }}
            onClick={() => navigate(`/android-app`)}
          >
            <img src={GooglePlay} height={'50px'} alt="Logo" />
          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ paddingLeft: 1, color: isDarkMode ? 'white' : '#003366', fontWeight: 'bold' }}
            onClick={() => navigate(`/ios-app`)}
          >
            <img src={appleStore} height={'45px'} alt="Logo" />
          </Typography>
        </Box>

      </Box>
    </div>
  );
};

export default HomePage;
