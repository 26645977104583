import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";

export const getBundlesRequest = () => ({
    type: actionTypes.GET_BUNDLES_REQUEST,
});
export const getBundlesSuccess = (data) => ({
    type: actionTypes.GET_BUNDLES_SUCCESS,
    payload: data,
});

export const getBundlesFailure = (error) => ({
    type: actionTypes.GET_BUNDLES_FAILURE,
    payload: error
});

export const getBundlesData = (id) => {
    console.log("id inside bundle action", id);
    return async(dispatch) => {
        dispatch(getBundlesRequest());
        try{
            const {data} = await api.getBundles(id);
            console.log("data in action", data);
            dispatch(getBundlesSuccess(data));
            return data;
        }catch(error) {
            dispatch(getBundlesFailure(error));
        }
    }
}

export const bundlePaymentSuccess = async (paymentData) => {
    const { data } = await api.bundlePaymentSuccess(paymentData);
    return data;
};

export const bundlePaymentBeacon = async (bundle_id) => {
    const { data } = await api.bundlePaymentBeacon(bundle_id);
    return data;
};