import { Box } from '@mui/material'
import React from 'react'

function SupportTicket() {
    return (
        <>
            <Box sx={{ marginLeft: 8, marginRight: 8, padding: '40px' }}>
                <div class="container pt-5 content-header">
                    <h1 class="pb-3 border-bottom">Please write email to
                        support@awesomeinnovators.com</h1>

                </div>

                <a href="whatsapp://send?abid=918765140002&text=">Send Message on Whatsapp</a>
            </Box>

        </>
    )
}

export default SupportTicket