import React, { useState } from 'react';
import { Button, TextField, Container, Typography, Box, CircularProgress, InputAdornment } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LogIn } from '../redux/actions/authActions';

const Login = () => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmitNumber = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await dispatch(LogIn({ mobile: mobileNumber, tenant: "rajoffset" }));
      if (response && response.data && response.data.RefId) {
        navigate(`/verify-otp`, {
          state: {
            referenceId: response.data.RefId,
            mobileNumber: response.data.mobile,
            tenant: response.data.tenant
          }
        });
      } else {
        setError('Please refresh your browser.');
      }
    } catch (err) {
      setError('Failed to send OTP. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 8 }}>
        <Typography component="h1" variant="h5">Login</Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Mobile Number"
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img
                  src="https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg"
                  alt="Indian Flag"
                  style={{ width: '24px', height: '16px', marginRight: '8px' }}
                />
                <span>+91</span>
              </InputAdornment>
            ),
          }}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSubmitNumber}
          disabled={mobileNumber.length !== 10 || isNaN(mobileNumber)}
          sx={{ marginBottom: '16px' }}
        >
          {loading ? <CircularProgress size={24} /> : 'Sign-in to RajOffset'}
        </Button>
        {error && <Typography color="error">{error}</Typography>}
      </Box>
    </Container>
  );
};

export default Login;
