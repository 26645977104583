import React from 'react';
import { Box, Typography, Avatar, List, ListItem, ListItemIcon, ListItemText, Grid, Paper, ListItemButton, useMediaQuery, Divider } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import LockIcon from '@mui/icons-material/Lock';
import PeopleIcon from '@mui/icons-material/People';
import PaymentIcon from '@mui/icons-material/Payment';
import SecurityIcon from '@mui/icons-material/Security';
import { useTheme } from '@mui/material/styles';

const SidebarItem = ({ text, icon }) => (
    <ListItem disablePadding>
        <ListItemButton>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={text} />
        </ListItemButton>
    </ListItem>
);

const Dashboard = () => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down('sm'));
    const isMedium = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh' }}>
            {/* Sidebar */}
            {isMatch ? (
                <>
                    <Box sx={{ flex: 1, p: 3 }}>
                   {/* <Box
                        sx={{
                            width: { },
                            bgcolor: '#f5f5f5',
                            p: 2,
                            alignItems: 'center',
                            borderRight: '1px solid #e0e0e0',
                        }}
                    >
                        <List sx={{ display: 'flex' }}>
                            <SidebarItem text="Home" icon={<HomeIcon />} />
                            <SidebarItem text="Personal info" icon={<InfoIcon />} />
                            <SidebarItem text="Data and privacy" icon={<SecurityIcon />} />
                            <SidebarItem text="Security" icon={<LockIcon />} />
                            <SidebarItem text="People and sharing" icon={<PeopleIcon />} />
                            <SidebarItem text="Payments and subscriptions" icon={<PaymentIcon />} />
                            <SidebarItem text="About" icon={<InfoIcon />} />
                        </List>
                    </Box> */}
                        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', mb: 4 }}>
                            <Avatar sx={{ bgcolor: 'green', width: 100, height: 100, fontSize: 40 }}>S</Avatar>
                            <Box sx={{ textAlign: 'center', mt: 2 }}>
                                <Typography variant="h5">Welcome, Sachin Chaurasiya</Typography>
                                <Typography sx={{ mt: 2, color: '#424242' }}>
                                    Manage your info, privacy, and security to make Google work better for you.
                                </Typography>
                            </Box>
                        </Box>

                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <Paper sx={{ p: 2 }}>
                                    <Typography variant="h6">Privacy & personalization</Typography>
                                    <Typography sx={{ mt: 1 }}>
                                        See the data in your Google Account and choose what activity is saved, to personalize your Google experience.
                                    </Typography>

                                    <Typography sx={{ mt: 2, color: 'blue', cursor: 'pointer' }}>Manage your data and privacy</Typography>
                                </Paper>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Paper sx={{ p: 2 }}>
                                    <Typography variant="h6">You have security tips</Typography>
                                    <Typography sx={{ mt: 1 }}>
                                        Security tips found in the Security Check-up.
                                    </Typography>
                                    <Typography sx={{ mt: 2, color: 'blue', cursor: 'pointer' }}>Review security tips</Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>
                </>

            ) : (
                <>
                    <Box
                        sx={{
                            width: { xs: '100%', sm: 250 },
                            backgroundColor: theme.palette.mode === "dark" ? '#333' : '#f5f5f5',
                            color: theme.palette.text.primary,
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            borderRight: '1px solid #e0e0e0',
                        }}
                    >
                        <List sx={{ width: '100%' }}>
                            <SidebarItem text="Home" icon={<HomeIcon />} />
                            <SidebarItem text="Personal info" icon={<InfoIcon />} />
                            <SidebarItem text="Data and privacy" icon={<SecurityIcon />} />
                            <SidebarItem text="Security" icon={<LockIcon />} />
                            <SidebarItem text="People and sharing" icon={<PeopleIcon />} />
                            <SidebarItem text="Payments and subscriptions" icon={<PaymentIcon />} />
                            <SidebarItem text="About" icon={<InfoIcon />} />
                        </List>
                    </Box>

                    {/* Main Content */}
                    <Box sx={{ flex: 1, p: 3 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', mb: 4 }}>
                            <Avatar sx={{ bgcolor: 'green', width: 100, height: 100, fontSize: 40 }}>S</Avatar>
                            <Box sx={{ ml: 3, textAlign: 'center', marginTop: 5 }}>
                                <Typography variant="h5">Welcome, Sachin Chaurasiya</Typography>
                                <Typography sx={{ marginTop: 5 }}>
                                    Manage your info, privacy, and security to make Google work better for you.
                                </Typography>
                            </Box>
                        </Box>

                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <Paper sx={{ p: 2 }}>
                                    <Typography variant="h6">Privacy & personalization</Typography>
                                    <Typography sx={{ mt: 1 }}>
                                        See the data in your Google Account and choose what activity is saved, to personalize your Google experience.
                                    </Typography>
                                    <Typography sx={{ mt: 2, color: 'blue', cursor: 'pointer' }}>Manage your data and privacy</Typography>
                                </Paper>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Paper sx={{ p: 2 }}>
                                    <Typography variant="h6">You have security tips</Typography>
                                    <Typography sx={{ mt: 1 }}>
                                        Security tips found in the Security Check-up.
                                    </Typography>
                                    <Typography sx={{ mt: 2, color: 'blue', cursor: 'pointer' }}>Review security tips</Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}

        </Box>
    );
};

export default Dashboard;
