import React from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import FolderIcon from '@mui/icons-material/Folder';
import ChatIcon from '@mui/icons-material/Chat';

const ContactUsPage = () => {
    const handleWhatsAppOpen = () => {
        window.open("whatsapp://send?abid=918765140002&text=","_blank");
    }
  return (
    <Box sx={{ padding: '40px', backgroundColor: '#f5f5f5', textAlign: 'center', }}>
      <Typography variant="h4" sx={{ marginBottom: '40px' }}>
        Contact Us
      </Typography>

      <Grid container spacing={4} justifyContent="center">
        {/* By Phone Section */}
        <Grid item xs={12} md={4}>
          <Box>
            <PhoneIcon sx={{ fontSize: 60, color: '#ff5722' }} />
            <Typography variant="h6" sx={{ marginTop: '20px', fontWeight: 'bold' }}>
              BY WhatsApp
            </Typography>
            <Typography variant="body1" sx={{ marginTop: '10px' }}>
              24x7 Support Available 
            </Typography>
            
            <Button variant="outlined" onClick={handleWhatsAppOpen} sx={{ marginTop: '20px', color: '#ff5722', borderColor: '#ff5722' }}>
              Send Message
            </Button>
          </Box>
        </Grid>

        {/* Start a New Case Section */}
        <Grid item xs={12} md={4}>
          <Box>
            <FolderIcon sx={{ fontSize: 60, color: '#ff5722' }} />
            <Typography variant="h6" sx={{ marginTop: '20px', fontWeight: 'bold' }}>
              START A NEW CASE
            </Typography>
            <Typography variant="body1" sx={{ marginTop: '10px' }}>
              Just send us your questions or concerns by starting a new case and we will give you the help you need.
            </Typography>
            <Button variant="outlined" disabled sx={{ marginTop: '20px', color: '#ff5722', borderColor: '#ff5722' }}>
              START HERE
            </Button>
          </Box>
        </Grid>

        {/* Live Chat Section */}
        <Grid item xs={12} md={4}>
          <Box>
            <ChatIcon sx={{ fontSize: 60, color: '#ff5722' }} />
            <Typography variant="h6"  sx={{ marginTop: '20px', fontWeight: 'bold' }}>
              LIVE CHAT
            </Typography>
            <Typography variant="body1" sx={{ marginTop: '10px' }}>
              Chat with a member of our in-house team.
            </Typography>
            <Button variant="outlined" disabled sx={{ marginTop: '20px', color: '#ff5722', borderColor: '#ff5722' }}>
              START CHAT
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactUsPage;