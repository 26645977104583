import * as actionTypes from "../actionTypes.js";

const initialState = {
    profileData: [],
    loading: false,
    error: null,
}

const multiProfileReducers = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.GET_ALL_PROFILES_REQUEST:
            return{
                ...state,
                loading:true,
                error: null,
            };
        case actionTypes.GET_ALL_PROFILES_SUCCESS:
            return{
                ...state,
                profileData: action.payload,
                loading: false,
                error: null,
            };
        case actionTypes.GET_ALL_PROFILES_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload
            };
        case actionTypes.ADD_PROFILE_DETAILS_REQUEST:
            return{
                ...state,
                loading: true,
                error: null
            };
        case actionTypes.ADD_PROFILE_DETAILS_SUCCESS:
            state.profileData.data.records = [...state.profileData.data.records, action.payload]
            return{
                ...state,
                profileData: state.profileData,
                loading: false,
            };
        case actionTypes.ADD_PROFILE_DETAILS_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload
            };
        case actionTypes.GET_SINGLE_PROFILE_DETAILS_REQUEST:
            return{
                ...state,
                loading:true,
                error: null,
            };
        case actionTypes.GET_SINGLE_PROFILE_DETAILS_SUCCESS:
            console.log("single profile reducer", action.payload);
            return{
                ...state,
                profileData: action.payload,
                loading: false,
                error: null,
            };
        case actionTypes.GET_SINGLE_PROFILE_DETAILS_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload
            };
        case actionTypes.UPDATE_PROFILE_DETAILS_REQUEST:
            return{
                ...state,
                loading: true,
                error: null
            };
        case actionTypes.UPDATE_PROFILE_DETAILS_SUCCESS:
            state.profileData.data.records = state.profileData.data.records.map(profile => 
                profile.profileId === action.payload.profileId ? action.payload: profile)
            return{
                ...state,
                profileData: state.profileData,
                loading: false,
                error: null,
            };
        case actionTypes.UPDATE_PROFILE_DETAILS_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload
            };
        case actionTypes.DELETE_PROFILE_REQUEST:
            return{
                ...state,
                loading: true,
                error: null
            };
        case actionTypes.DELETE_PROFILE_SUCCESS:
            state.profileData.data.records = state.profileData.data.records.filter(profile => profile.profileId !== action.payload)
            return{
                ...state,
                profileData: state.profileData,
                loading: false,
                error: null,
            };
        case actionTypes.DELETE_PROFILE_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }

}
export default multiProfileReducers