import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { completeProfileDetailsData } from '../../redux/actions/completeProfileActions';

const CompleteProfile = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const dispatch = useDispatch();

  const handleProfileDetailsSubmit = async(e) => {
    e.preventDefault();
    // Handle form submission, e.g., send data to an API or log it
    const profileData = {
      firstName,
      lastName,
      email,
    };
    await dispatch(completeProfileDetailsData(profileData));
    console.log("profile data", profileData);
    
  };

  return (
    // <Container maxWidth="sm" style={{ marginTop: '20px' }}>
    //   <Box 
    //     sx={{ 
    //       backgroundColor: '#cfd8dc', 
    //       padding: '20px', 
    //       borderRadius: '10px',
    //       boxShadow: 2
    //     }}
    //   >
    //     <Typography variant="h5" align="center" gutterBottom>
    //       Complete Your Profile
    //     </Typography>
    //     <form onSubmit={handleProfileDetailsSubmit}>
    //       <TextField
    //         fullWidth
    //         required
    //         label="First Name"
    //         value={firstName}
    //         onChange={(e) => setFirstName(e.target.value)}
    //         margin="normal"
    //         variant="outlined"
    //       />
    //       <TextField
    //         fullWidth
    //         required
    //         label="Last Name"
    //         value={lastName}
    //         onChange={(e) => setLastName(e.target.value)}
    //         margin="normal"
    //         variant="outlined"
    //       />
    //       <TextField
    //         fullWidth
    //         required
    //         label="Email"
    //         value={email}
    //         type="email"
    //         onChange={(e) => setEmail(e.target.value)}
    //         margin="normal"
    //         variant="outlined"
    //       />
    //       <Button 
    //         type="submit" 
    //         fullWidth 
    //         variant="contained" 
    //         color="primary"
    //         style={{ marginTop: '20px' }}
    //       >
    //         Submit
    //       </Button>
    //     </form>
    //   </Box>
    // </Container>
    <>
    <Typography variant="h5" align="center" gutterBottom>
     Hi User! Welcome to RajOffset.
     </Typography>
     <Typography variant="h6" align="center" gutterBottom>
      You will find all your cards in "My Cards" section.
      </Typography>
    </>
  );
};

export default CompleteProfile;