import React from 'react';
import { Typography, Box } from '@mui/material';

function ComingSoon() {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f0f0f0',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h3" gutterBottom >
        OUR NEW WEBSITE IS COMING SOON
      </Typography>
      <Typography variant="h5" color="textSecondary">
        20 Oct 2024 00:01 AM
      </Typography>
    </Box>
  );
}

export default ComingSoon;