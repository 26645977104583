import React, { useState, useEffect } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Button, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function CardSubmittedSuccessfullyScreen() {
    const navigate = useNavigate();
    const [timer, setTimer] = useState(30);

    useEffect(() => {
        if (timer === 0) {
            navigate('/login');
        }

        const countdown = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
        }, 1000);

        return () => clearInterval(countdown);
    }, [timer, navigate]);
    
    useEffect(() => {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container maxWidth='sm' style={{ textAlign: 'center', marginTop: '50px' }}>
            <CheckCircleOutlineIcon style={{ fontSize: 80, color: 'green' }} />
            <Typography variant='h4' style={{ marginTop: '20px' }}>
                Card Submitted Successfully
            </Typography>
            <Typography variant='body1' style={{ margin: '20px 0' }}>
                Please use the same mobile number to login and view your card. Your card will be available in My Cards section.
            </Typography>
            <Typography variant='body1' style={{ margin: '20px 0' }}>
                Redirecting you to login page in {timer} seconds...
            </Typography>
            <Button onClick={() => navigate('/dashboard')} variant='contained' color='primary' sx={{ marginBottom: 5 }}>
                Login to view card
            </Button>
        </Container>
    );
}

export default CardSubmittedSuccessfullyScreen;