import { Box } from '@mui/material'
import React from 'react'

function TermsAndConditions() {
    return (
        <>
            <Box sx={{marginLeft: 8, marginRight: 8}}>
                <div class="container pt-5 content-header">
                    <h1 class="pb-3 border-bottom">Terms and Conditions</h1>
                    <div class="content-body">
                        <p>Awesome Innovators (<strong>“Firm”</strong>) owns and operates the
                            website www.rajoffset.com (and affiliate websites awesomeinnovators.com) (collectively and individually referred
                            to herein as the <strong>“Website”</strong>). By accessing the Website, you agree with and
                            consent to be bound by (i) these terms and conditions (“<a href="https://www.rajoffset.com/terms-and-conditions" data-type="URL" data-id="https://www.rajoffset.com/terms-and-conditions">Terms and Conditions</a>”); and (ii) privacy policy
                            at link here: <a href="https://www.rajoffset.com/privacy-policy">https://www.rajoffset.com/privacy-policy</a><strong>,</strong> which privacy policy is incorporated herein by reference. The words “you”
                            and “user” as used herein refer to all individuals and/or entities accessing this website for any reason.</p>
                        <p>This document is published in accordance with the provisions of Rule 3 (1) of the
                            Information Technology (Intermediaries Guidelines) Rules, 2011.</p>
                        <p><strong><strong>NATURE OF THE
                            WEBSITE</strong></strong></p>
                        <p>The Website intends&nbsp;to act as a platform to solely to assist companies, groups and
                            individuals to send digital invitation and purchase invitation cards, subscribe to printed invitation cards,
                            subscribe to digital invoice management, and for no other purposes.</p>
                        <p><strong><strong>ELIGIBILITY TO USE THE
                            WEBSITE</strong></strong></p>
                        <p>As a condition of your use of this Website, you warrant that (i) you are at least 18 years
                            of age, (ii) you possess the legal authority to create a binding legal obligation, (iii) you will use this
                            Website in accordance with the Terms of Use, (iv) you will only use this Website to make legitimate invitations
                            for yourself or others or on behalf of those parties within your Company for whom you are legally authorized to
                            act, (v) all information supplied by you on this Website is true, accurate, current and complete, and (vi) you
                            will safeguard your rajoffset.com (digitalinvitation.rajoffset.com) account information and will supervise and
                            be completely responsible for any use of your account by anyone other than you. To the extent that you are
                            making invitations on behalf of another members of your Company, your family, and your group, you represent and
                            warrant that such members agrees to and is bound by these terms and conditions.</p>
                        <p>You agree and undertake that your access of the Website or the utilisation of the
                            facilities provided on the Website does not in any manner contravene the local laws or regulations of the
                            territory from which you access the Website or the facilities provided thereunder.</p>
                        <p>Notwithstanding anything contained herein, the Company (Firm) hereby reserves the right to
                            deny access to the Website or any part thereof to any person, without assigning any reason therefor&nbsp;but not
                            limited to, for violation of the Terms of Use.</p>
                        <p><strong><strong>REGISTRATION</strong></strong>
                        </p>
                        <p>For accessing and using the services on the Website, you may have to register an account
                            with the Website. In this regard, you agree and undertake:</p>
                        <p>To provide true, accurate, current and complete information about yourself in all aspects
                            as may be prompted by the Website’s registration form (such information being the&nbsp;<strong><strong>“Registration Data”</strong></strong>).</p>
                        <p>To maintain and promptly update the Registration Data to keep it true, accurate, current
                            and complete.</p>
                        <p>You will not create more than one account.</p>
                        <p>If the Company (Firm) disables or suspends your account, you shall not create another one
                            without our permission.</p>
                        <p>You shall not share your password with any person.</p>
                        <p>You shall not assign, transfer or otherwise permit any other person to operate your
                            account.</p>
                        <p>You represent and warrant that registering on the Website and availing the services thereon
                            shall not constitute a breach of any obligation by which you are bound whether arising by contract or operation
                            of law.</p>
                        <p>You agree and acknowledge that the Company may delete or suspend your account in the event
                            that you violate any of the Terms and Conditions or applicable law.</p>
                        <p><strong><strong>YOUR USE OF THE
                            WEBSITE</strong></strong></p>
                        <p>The content and information on this Website (including, but not limited to, price and
                            images, and other contents) you agree not to otherwise modify, copy, distribute, transmit, display, perform,
                            reproduce, publish, license, create derivative works from, transfer, or sell or re-sell any information,
                            software, products, or services obtained from or through this Website.</p>
                        <p>Additionally, you agree not to: (i) use this Website or its contents for any commercial
                            purpose unless allowed and authorised by the Company; (ii) make any speculative, false, or fraudulent
                            activities; (iii) access, monitor or copy any content or information of this Website using any robot, spider,
                            scraper or other automated means or any manual process for any purpose without our express written permission;
                            (iv) violate the restrictions in any robot exclusion headers on this Website or bypass or circumvent other
                            measures employed to prevent or limit access to this Website; (v) take any action that imposes, or may impose,
                            in our discretion, an unreasonable or disproportionately large load on our infrastructure; (vi) deep-link to any
                            portion of this Website (including, without limitation, the purchase path for any services) for any purpose
                            without our express written permission; or (vii) “frame”, “mirror” or otherwise incorporate any part of this
                            Website into any other website without our prior written authorization.</p>
                        <p>You agree and undertake that all information, material or content that you shall post on
                            the Website, including but not limited to any personal details, qualifications and experience, shall be accurate
                            and not misleading.</p>
                        <p>You shall not attempt to gain unauthorized access to any portion or feature of&nbsp;the
                            Website, or any other systems or networks connected to the Website or to any server, computer, network, or to
                            any of the services offered on or through the Website, by hacking or any other illegitimate means.</p>
                        <p><strong><strong><strong><strong>FEES PAYMENT</strong></strong></strong></strong>
                        </p>
                        <p>We reserves the right to charge listing fees for certain listings, as well as transaction
                            fees based on certain completed transactions using the services. We further reserves the right to alter any and
                            all fees from time to time, without notice.</p>
                        <p>The User shall be completely responsible for all charges, fees, duties, taxes, and
                            assessments arising out of the use of the services. In case, there is a short charging by us for listing,
                            services or transaction fee or any other fee or service because of any technical or other reason, it reserves
                            the right to deduct/charge/claim the balance subsequent to the transaction at its own discretion. Any increase
                            in the price charged by Company (Firm) on account of change in rate of taxes or imposition of new taxes by
                            Government shall have to be borne by customer.</p>
                        <p>The User shall request us for any refunds against the unutilized service for any reasons
                            within 15 days from the date of payment. Any applicable refunds would accordingly be processed as per the
                            defined policies. No refund would be payable for any requests made after the expiry of 15 days as above and all
                            unclaimed amounts for such unutilized services shall be deemed to have been forfeited.</p>
                        <p><strong><strong>USAGE OF CONTACT NUMBER / EMAIL
                            OF THE USER</strong></strong></p>
                        <p>We may send digital invitation, itinerary information, cancellation, payment confirmation,
                            refund status, schedule change or any such other information relevant for the transaction, via SMS or by voice
                            call on the contact number or by email to the email provided by the User at the time of Registration. We will
                            also send invitation information via SMS, and Email as per as our digital invitation policy. We may also contact
                            the User by voice call, SMS or email in case the User couldn’t or hasn’t concluded the invitation, for any
                            reason what so ever, to know the preference of the User for concluding the invitation and also to help the User
                            for the same.</p>
                        <p>&nbsp;The User hereby unconditionally consents that such communications via SMS and/ or
                            voice call by Awesome Innovators &amp; Raj Offset is (i) upon the request and authorization of the User, (ii)
                            ‘transactional’ and not an ‘unsolicited commercial communication’ as per the guidelines of Telecom Regulation
                            Authority of India (TRAI) and (iii) in compliance with the relevant guidelines of TRAI or such other authority
                            in India and abroad. The User will indemnify Awesome Innovators against all types of losses and damages incurred
                            by Awesome Innovators due to any action taken by TRAI, Access Providers (as per TRAI regulations) or any other
                            authority due to any erroneous compliant raised by the User on Awesome Innovators with respect to the
                            intimations mentioned above or due to a wrong number or email id being provided by the User for any reason
                            whatsoever.</p>
                        <p><strong><strong>ONUS OF THE
                            USER</strong></strong></p>
                        <p>Awesome Innovators is responsible only for the transactions that are done by the User
                            through Awesome Innovators. Awesome Innovators will not be responsible for screening, censoring or otherwise
                            controlling transactions, including whether the transaction is legal and valid as per the laws of the land of
                            the User.</p>
                        <p>The User warrants that they will abide by all such additional procedures and guidelines, as
                            modified from time to time, in connection with the use of the services. The User further warrants that they will
                            comply with all applicable laws and regulations regarding use of the services with respect to the jurisdiction
                            concerned for each transaction. The User represent and confirm that the User is of legal age to enter into a
                            binding contract and is not a person barred from availing the Services under the laws of India or other
                            applicable law.</p>
                        <p><strong><strong>DISCLAIMERS OF
                            WARRANTIES</strong></strong></p>
                        <p>You expressly acknowledge and agree that use of the Website is at your sole risk. To the
                            maximum extent permitted by applicable law, the Website and any services performed or provided by the Website
                            are provided “as is” and “as available”, without warranty of any kind, and the Company hereby disclaims all
                            warranties and conditions with respect to the Website and any services, either express, implied or statutory,
                            including, but not limited to, the implied warranties and/or conditions of merchantability, of satisfactory
                            quality, of fitness for a particular purpose, of accuracy, and non-infringement of third party rights. The
                            Company does not warrant against interference with your enjoyment of the Website, that the functions contained
                            in, or services performed or provided by, the Website will meet your requirements, that the operation of the
                            Website or services will be uninterrupted, virus-free or error-free, or that defects in the Website or services
                            will be corrected. No oral or written information or advice given by the Company shall create a warranty by the
                            Company. You shall be solely responsible for any damage to your property, including any device or computer
                            system from which the Website is accessed.</p>
                        <p>The Website displays, includes and makes available content, data, information, applications
                            or materials from third party website (<strong><strong>“Third Party
                                Materials”</strong></strong>) and may also provide links to certain third party web sites. By using the
                            Website, you acknowledge and agree that the Company is not responsible for examining or evaluating the content,
                            accuracy, completeness, legality, decency, quality or any other aspect of such Third Party Materials or third
                            party websites. The Company does not warrant or endorse and does not assume and will not have any liability or
                            responsibility to you or any other person for any Third Party Materials or any third party websites. Third Party
                            Materials and third party websites are provided solely as a convenience to you.</p>
                        <p>The information, software, products, and services published on this website may include
                            inaccuracies or errors, including pricing errors. In particular, the affiliated, co-branded and/or linked
                            website partners through whom we provide service (collectively, “Awesome Innovatos affiliates”) do not guarantee
                            the accuracy of, and disclaim all liability for any errors or other inaccuracies relating to the information and
                            description of the hotel other travel products displayed on this website (including, without limitation, the
                            pricing, photographs, general product descriptions, etc.), much of which information is provided by the
                            respective suppliers. In addition, we expressly reserves the right to correct any pricing errors on our website
                            and/or on pending invitation limit made under an incorrect price. In such event, we will offer you the
                            opportunity to keep your pending invitation limit at the correct price or, at your option, we will cancel your
                            invitation without penalty.</p>
                        <p>The Awesome Innovatos and its affiliates have no liability and will make no refund in the
                            event of any delay, cancellation, strike, force majeure or other causes beyond their direct control, and they
                            have no responsibility for any additional expense, omissions, delays, or acts of any government or authority.
                        </p>
                        <p><strong><strong>INTELLECTUAL
                            PROPERTY</strong></strong></p>
                        <p>You agree that the Website, including but not limited to the, source code, object code,
                            scripts and software used to implement the Website, is owned by the Company (Firm). You expressly agree and
                            undertake that you will not use such proprietary information or&nbsp;material in any way whatsoever except for
                            use of the Website in compliance with the terms of these Terms and Conditions.</p>
                        <p>With the exception of user Content submitted to the Website by you, all other content on
                            the Website is owned by the Company, and is subject to copyright, trade mark rights, and other intellectual
                            property rights of the company. The Company owns a copyright in the selection, coordination, arrangement and
                            enhancement of the content submitted by users.</p>
                        <p>No downloading, copying, redistribution, retransmission, publication or commercial
                            exploitation of any content on the Website is permissible without the express permission of the Company.</p>
                        <p>You&nbsp;shall not use the name, trademarks or logos of the Company without the prior
                            written consent of the Company. Nothing contained herein should be deemed to be an assignment or transfer of any
                            intellectual property right in your favour.</p>
                        <p>You may not decompile, disassemble, or reverse engineer the Website by any means
                            whatsoever, or alter, modify, enhance, or create a derivative work of the Website. You may not remove, alter, or
                            obscure any product identification, copyright, or other intellectual property notices on the Website.</p>
                        <p>You agree that any user Content that you may upload on the Website shall not infringe any
                            third party rights.</p>
                        <p>When you upload or post any user Content, you grant to the Company, a worldwide, perpetual,
                            non-exclusive, royalty-free, transferable license (with right to sub-licence) to use, reproduce, distribute,
                            prepare derivative works of, display, publish and perform that user Content or any part thereof in connection
                            with the provision of the services on the Website and otherwise in connection with the business of the Company,
                            including without limitation for promoting and redistributing part or all of the Website, in any format
                            whatsoever (now known or that may be invented or may be available for use in the future) and through any media
                            channels. The license granted by you hereunder shall survive even if you delete your account on the Website or
                            remove the user Content from the Website or otherwise stop accessing the Website.</p>
                        <p><strong><strong>INDEMNITY</strong></strong></p>
                        <p>You shall indemnify and hold harmless the Company, its promoters, licensees, affiliates,
                            subsidiaries, group companies (as applicable) and their respective officers, directors, agents, and employees,
                            from any claim or demand, or actions (including reasonable attorneys’ fees), made by any third party or penalty
                            imposed due to or arising out of your (i) use of the Website; (ii) breach of these Terms and Conditions and any
                            other policy in relation to the Website; (iii) any transaction that you may enter into pursuant to use of the
                            Website; (iv), your violation of any law, rules or regulations or the rights (including infringement of
                            intellectual property rights) of a third party.</p>
                        <p><strong><strong>LIMITATION OF
                            LIABILITY</strong></strong></p>
                        <p>In no event shall&nbsp;the Company, its promoters, licensees, affiliates, subsidiaries,
                            group companies (as applicable) and their respective officers, directors, agents, and employees, be liable for
                            any direct, indirect, incidental, special, consequential or punitive damages for any reason whatsoever including
                            without limitation for those arising out of or related to: (i) your use of or reliance upon the Website, and any
                            other content or information contained in the Website; (ii) any user Content that you post or upload; (iii) your
                            inability to use the Website or inability to secure an opportunity from an Opportunity Provider or avail the
                            services of a Talent ; (iv)&nbsp;any transaction that you may enter into pursuant to use of the Website.</p>
                        <p><strong><strong>PRIVACY
                            POLICY</strong></strong></p>
                        <p>The Privacy Policy of the Company is available here:&nbsp;<a href="https://www.rajoffset.com/terms-and-conditions">https://www.rajoffset.com/privacy-policy</a>. The
                            Privacy Policy is incorporated by way of reference to these&nbsp;<a href="https://www.rajoffset.com/terms-and-conditions">Terms and Conditions</a>&nbsp;(and is deemed to a part
                            of these&nbsp;<a href="https://www.rajoffset.com/terms-and-conditions">Terms and
                                Conditions</a>). Reference to the/these “Terms and Conditions” shall also include the Privacy Policy. You
                            hereby expressly agree and undertake to be bound by the Privacy Policy. If you do not agree to be bound by
                            the&nbsp;<a href="https://www.rajoffset.com/privacy-policy/">Privacy Policy</a>, please do
                            not install or otherwise use the Website.</p>
                        <p><strong><strong>ENTIRE
                            AGREEMENT</strong></strong></p>
                        <p>The Terms and Conditions, including the incorporated Privacy Policy and other terms
                            incorporated by reference, constitutes the entire agreement and understanding between you and the Company with
                            respect to the subject matter hereof and supersedes all prior or contemporaneous communications and proposals,
                            whether oral or written, between you and the Company with respect to such subject matter.</p>
                        <p><strong><strong>ASSIGNMENT</strong></strong>
                        </p>
                        <p>You shall not assign these Terms and Conditions, or any rights, benefits or obligations
                            hereunder without the express written permission of the Company. Any attempted assignment that does not comply
                            with these Terms and Conditions shall be null and void. The Company may assign these Terms and Conditions, in
                            whole or in part, to any third-party in its sole discretion.</p>
                        <p><strong><strong>AMENDMENT</strong></strong></p>
                        <p>The Company reserves its right at all times to modify any part of these Terms and
                            Conditions at its sole discretion. You agree to revisit the Terms and Conditions regularly to ensure that you
                            stay informed of any changes. Your use of the Website after the Company updates the Terms and Conditions will
                            constitute acceptance of the modified Terms and Conditions.</p>
                        <p><strong><strong>GOVERNING LAW</strong></strong>
                        </p>
                        <p>These Terms and Conditions (including the incorporated Privacy Policy) shall be governed by
                            the laws of India.</p>
                        <p><strong><strong>DISPUTE
                            RESOLUTION</strong></strong></p>
                        <p>In the event of any dispute or difference between you and the Company arising out or in any
                            way relating these Terms and Conditions (and/or the Privacy Policy), the same shall be referred to arbitration
                            to be conducted by a sole arbitrator appointed by the Company. The arbitration proceedings shall be conducted
                            in&nbsp;Mirzapur (Uttar Pradesh – India), in accordance with the provisions of the Arbitration and Conciliation
                            Act, 1996.</p>
                        <p>Subject to the arbitration provisions above, the courts in Mirzapur, Uttar-Pradesh shall
                            have exclusive jurisdiction in relation to these Terms and Conditions (and/or the Privacy Policy). Awesome
                            Innovators.</p>
                    </div>
                </div>
            </Box>

        </>
    )
}

export default TermsAndConditions