import * as actionTypes from "../actionTypes.js";

const initialState = {
    cardsData: [],
    loading: false,
    error: null,
}

const allCardsDataReducer = (state= initialState, action) => {
    switch(action.type){
        case actionTypes.GET_ALL_CARDS_DATA_REQUEST:
            return{
                ...state,
                loading: true,
                error: null
            };
        case actionTypes.GET_ALL_CARDS_DATA_SUCCESS:
            return{
                ...state,
                loading: false,
                cardsData: action.payload,
                error: null,
            };
        case actionTypes.GET_ALL_CARDS_DATA_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state;
    }
};
export default allCardsDataReducer