import { Box } from '@mui/material'
import React from 'react'

function DeleteAccountAndPolicy() {
    return (
        <>
            <Box sx={{ marginLeft: 8, marginRight: 8 }}>
                <div class="container pt-5 content-header">
                    <h1 class="pb-3 border-bottom">Delete Account Policy</h1>


                    <p>This policy outlines the procedures for the deletion of user accounts within our system. Account deletion requests may come from users, administrators, or other authorized personnel. The objective is to ensure the secure and timely removal of user accounts in compliance with data protection and privacy regulations.</p>

                    <p><b>1. Account Deletion Request:</b></p>

                    <p>Users or administrators may submit an account deletion request by submitting the Delete Account Request Form. The request must include the username, associated email address, and other details and a clear statement of the intention to delete the account.</p>

                    <p><b>2. Verification:</b></p>

                    <p>Before processing an account deletion request, the IT department will verify the identity of the person making the request. This may involve confirming details through established communication channels or requiring additional authentication.</p>

                    <p><b>3. Backup and Data Retention:</b></p>

                    <p>Prior to deletion, all relevant data associated with the user account will be securely backed up. This backup will be retained for a period defined by data retention policies to comply with legal and regulatory requirements.</p>

                    <p><b>4. Deletion Process:</b></p>

                    <p>Upon successful verification and backup, the user account will be permanently deleted from the system. This includes user profiles, access credentials, and any associated data.</p>

                    <p><b>5. Communication:</b></p>

                    <p>The user making the deletion request will be notified upon completion of the deletion process. This communication will confirm the successful removal of the account.</p>

                    <p><b>6. Exceptions:</b></p>

                    <p>Exceptions may be considered for certain circumstances, such as ongoing investigations or legal requirements. Any exceptions must be documented and approved by the appropriate authority.</p>

                    <p><b>7. Record Keeping:</b></p>

                    <p>Records of account deletion requests, verifications, and deletion activities will be maintained for auditing and compliance purposes.</p>

                    <p><b>8. Deadline:</b></p>

                    <p>Upon successful verification of the request submitted to us. Your account will be removed from the current system within 7 working days. Please note the verification process may take time.</p>

                    <p><b>9. Compliance:</b></p>

                    <p>This policy is designed to comply with relevant data protection laws and regulations. Any updates or changes to this policy will be made to ensure ongoing compliance.</p>

                    <p><b>10. Review:</b></p>

                    <p>This policy will be reviewed periodically to assess its effectiveness and relevance. Updates will be made as necessary to address changes in technology, regulations, or organizational requirements.</p>

                    <p><i>*Note: This policy is subject to revision according to Govt, Appropriate Authority (Dept) or Court directions. Some process may conflict wrt Article 21 of ICA. You will be informed (if there is conflict).</i></p>



                </div>
            </Box>

        </>
    )
}

export default DeleteAccountAndPolicy