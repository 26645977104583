import * as actionTypes from "../actionTypes.js";

const initialState = {
    bundlesData: [],
    loading: false,
    error: null,
}

const bundleReducer = (state= initialState, action) => {
    switch(action.type){
        case actionTypes.GET_BUNDLES_REQUEST:
            return{
                ...state,
                loading: true,
                error: null
            };
        case actionTypes.GET_BUNDLES_SUCCESS:
            return{
                ...state,
                loading: false,
                bundlesData: action.payload,
                error: null,
            };
        case actionTypes.GET_BUNDLES_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state;
    }
};
export default bundleReducer