import React, { useEffect } from 'react';
import { Typography, Box, Button, Container, List, ListItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AboutUsPage = () => {
    const navigate = useNavigate();
    return (
        <Box
            sx={{
                padding: '50px 0',
            }}
        >
            <Container maxWidth="md" sx={{}}>
                <Typography
                    variant="h3"
                    sx={{
                        fontWeight: 'bold',
                        color: '#0097a7',
                        mb: 4,
                        textAlign: 'center',
                    }}
                    align="center"
                    gutterBottom>
                    ABOUT US
                </Typography>
                <Box
                    sx={{
                        backgroundColor: '#eeeeee',
                        color: 'black',
                        padding: '70px 40px',
                        borderRadius: '8px',
                        marginTop: '10px', 
                    }}
                >
                    <Typography variant="h4" gutterBottom sx={{ marginBottom: '20px' }}>
                        WHO WE ARE
                    </Typography>
                    <Typography variant="body1" paragraph sx={{ marginBottom: '20px', lineHeight: '1.7' }}>
                        Welcome to Raj Offset, where tradition meets innovation! With over 30 years of experience in the printing industry, we have proudly established ourselves as a trusted name in creating exquisite invitation cards. Our journey began with a passion for helping people celebrate their special moments, and today, we are excited to bring that experience online. At Raj Offset, we specialize in a wide variety of invitation cards, including:
                    </Typography>

                    <ul style={{ fontWeight: 600, marginBottom: '30px', paddingLeft: '20px', lineHeight: '1.8' }}> 
                        <li>Shaadi Cards</li>
                        <li>Engagement Invitations</li>
                        <li>Haldi & Mehndi Cards</li>
                        <li>Birthday Invitations</li>
                        <li>Khatha Cards</li>
                        <li>Mundan & Barahi Invitations</li>
                        <li>Visiting Cards</li>
                    </ul>

                    <Typography variant='h6' gutterBottom sx={{ marginBottom: '10px' }}>
                        A Legacy of Quality
                    </Typography>
                    <Typography variant="body1" paragraph sx={{ marginBottom: '30px', lineHeight: '1.7' }}>
                        With three decades of experience, we have honed our craft to deliver not just products but cherished memories. Our commitment to quality ensures that every card we produce reflects the love and joy of your celebrations.
                    </Typography>

                    <Typography variant='h6' gutterBottom sx={{ marginBottom: '10px' }}>
                        Affordable Pricing
                    </Typography>
                    <Typography variant="body1" paragraph sx={{ marginBottom: '30px', lineHeight: '1.7' }}>
                        We believe that beautiful invitations should be accessible to everyone. Our competitive pricing allows you to create stunning digital cards and videos without breaking the bank.
                    </Typography>

                    <Typography variant='h6' gutterBottom sx={{ marginBottom: '10px' }}>
                        Seamless Online Experience
                    </Typography>
                    <Typography variant="body1" paragraph sx={{ marginBottom: '30px', lineHeight: '1.7' }}>
                        Our website and mobile app are designed with you in mind. Enjoy a hassle-free experience as you browse our extensive collection, customize your designs, and place orders—all from the comfort of your home.
                    </Typography>

                    <Typography variant='h6' gutterBottom sx={{ marginBottom: '10px' }}>
                        Personalized Touch
                    </Typography>
                    <Typography variant="body1" paragraph sx={{ marginBottom: '30px', lineHeight: '1.7' }}>
                        We understand that each celebration is unique. That’s why we offer customizable templates and designs tailored to your specific needs. Our dedicated team is here to assist you every step of the way, ensuring your vision comes to life.
                    </Typography>

                    <Typography variant='h6' gutterBottom sx={{ marginBottom: '10px' }}>
                        Eco-Friendly Practices
                    </Typography>
                    <Typography variant="body1" paragraph sx={{ marginBottom: '30px', lineHeight: '1.7' }}>
                        In addition to our commitment to quality and affordability, we prioritize sustainability. Our printing processes are designed to minimize waste and reduce our environmental footprint, so you can celebrate responsibly. Join us as we continue to innovate and inspire, making every celebration unforgettable!
                    </Typography>
                </Box>

            </Container>
        </Box>
    );
};

export default AboutUsPage;