import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from "react-router-dom";

const useAuth = (user) => {
    console.log(user);
    if (user.isAuthenticated) {
        return true;
    } else {
        return false;
    }
};

const ProtectedRoutes = (props) => {
    const state = useSelector(state => state.auth);
    const auth = useAuth(state);
    const location = useLocation();

    useEffect(() => { }, [location]);

    return auth ? (
        <>
            <Outlet />
        </>
    ) : (
        <Navigate to="/login" />
    );
};

export default ProtectedRoutes;