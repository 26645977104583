import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createCardForDiwaliOfferData } from '../../redux/actions/diwaliOfferActions';

function BundleCodeDetails() {


    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const [formData, setFormData] = useState({
        "Tilak Event Date": '',
        "Tilak Event Time": '',
        "Tilak Venue": '',
        "Haldi Event Date": '',
        "Haldi Event Time": '',
        "Haldi Venue": '',
        "Mehndi Event Date": '',
        "Mehndi Event Time": '',
        "Mehndi Venue": '',
        "Sangeet Event Date": '',
        "Sangeet Event Time": '',
        "Sangeet Venue": '',
        "Wedding Event Date": '',
        "Wedding Event Time": '',
        "Wedding Venue": '',
        "Bride Name": '',
        "Bride Parent Name": '',
        "Bride Address": '',
        "Groom Name": '',
        "Groom Parent Name": '',
        "Groom Address": '',
        "Family Members": '',
        "Card For": 'bride',
        "Customer Name": '',
        "Customer Mobile": '',
        "Customer Email": ''
    });

    const [timeLeft, setTimeLeft] = useState('');
    useEffect(() => {
        const calculateTimeLeft = () => {
            const now = new Date();
            const currentHour = now.getHours();
            const currentMinute = now.getMinutes();
            const currentSecond = now.getSeconds();

            // Calculate the end of the current slot
            const slotEndHour = Math.ceil((currentHour + 1) / 6) * 6;
            const slotEnd = new Date(now);
            slotEnd.setHours(slotEndHour, 0, 0, 0);

            // Calculate the remaining time
            const timeDiff = slotEnd - now;
            const hours = Math.floor(timeDiff / (1000 * 60 * 60));
            const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

            setTimeLeft(`${String(hours).padStart(2, '0')} hr ${String(minutes).padStart(2, '0')} min ${String(seconds).padStart(2, '0')} sec`);
        };

        calculateTimeLeft();
        const timer = setInterval(calculateTimeLeft, 1000);

        return () => clearInterval(timer);
    }, []);


    const [selectedSide, setSelectedSide] = useState();
    const handleFieldChange = (field, event) => {

        const { name, value } = event.target;


        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        console.log("form Data", formData);

    };



    useEffect(() => {
        if (selectedSide) {
            console.log("Updated Marriage Side:", selectedSide);
            if (selectedSide === 'groom') {
                // Specific condition action
                console.log("Condition matched!");
            }
        }
    }, [selectedSide]);


    const isFormValid = () => {
        return formData["Haldi Event Date"] && formData["Haldi Event Time"] && formData["Mehndi Event Date"] && formData["Mehndi Event Time"] && formData["Sangeet Event Date"] && formData["Sangeet Event Time"] && formData["Wedding Event Date"] && formData["Wedding Event Time"] && formData["Bride Name"] && formData["Bride Parent Name"] && formData["Bride Address"] && formData["Groom Name"] && formData["Groom Parent Name"] && formData["Groom Address"] && formData["Family Members"];
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options); 
    };
    
    const formatTime = (timeString) => {
        if (!timeString) return '';
        
        const [hours, minutes] = timeString.split(":");
        const hours12 = ((+hours % 12) || 12).toString().padStart(2, '0'); 
        const period = +hours >= 12 ? 'PM' : 'AM'; 
        return `${hours12}:${minutes} ${period}`; 
    };

    const handleSubmit = async () => {
        const transformedData = {
            campaign: "diwali-sale",
            customer_name: formData["Customer Name"],
            customer_mobile: formData["Customer Mobile"],
            customer_email: formData["Customer Email"],
            card_fields: {
                "Tilak Date": formatDate(formData["Tilak Event Date"]),
                "Tilak Time": formatTime(formData["Tilak Event Time"]),
                "Tilak Venue": formData["Tilak Venue"],
                "Haldi Date": formatDate(formData["Haldi Event Date"]),
                "Haldi Time": formatTime(formData["Haldi Event Time"]),
                "Haldi Venue": formData["Haldi Venue"],
                "Mehndi Date": formatDate(formData["Mehndi Event Date"]),
                "Mehndi Time": formatTime(formData["Mehndi Event Time"]),
                "Mehndi Venue": formData["Mehndi Venue"],
                "Sangeet Date": formatDate(formData["Sangeet Event Date"]),
                "Sangeet Time": formatTime(formData["Sangeet Event Time"]),
                "Sangeet Venue": formData["Sangeet Venue"],
                "Wedding Date": formatDate(formData["Wedding Event Date"]),
                "Wedding Time": formatTime(formData["Wedding Event Time"]),
                "Wedding Venue": formData["Wedding Venue"],
                "Bride Name": formData["Bride Name"],
                "Bride Parent Name": formData["Bride Parent Name"],
                "Bride Address": formData["Bride Address"],
                "Groom Name": formData["Groom Name"],
                "Groom Parent Name": formData["Groom Parent Name"],
                "Groom Address": formData["Groom Address"],
                "Family Members": formData["Family Members"],
                "Card For": formData["Card For"]
            }
        };
    
        console.log("Transformed Data:", transformedData);
    
        const data = await createCardForDiwaliOfferData(transformedData); 
    };



    return (
        <Box sx={{ padding: 2, marginX: 'auto', maxWidth: '1200px', marginTop: 5, overflowX: 'hidden' }}>


            <>
                <Typography sx={{ textAlign: 'left', fontSize: '0.875rem', fontWeight: 650, fontFamily: 'inherit' }}>
                    Diwali Combo Offer
                </Typography>
                <Typography sx={{ fontSize: '1.8rem', textAlign: 'left', fontFamily: 'inherit' }}>
                    You will get Total 16 Invitation Card and Video Only at Rs. 399 /-
                </Typography>


                <Typography sx={{ textAlign: 'left', fontSize: '0.875rem', fontWeight: 650, fontFamily: 'inherit' }}>
                    2 Haldi PDF Card
                </Typography>
                <Typography sx={{ textAlign: 'left', fontSize: '0.875rem', fontWeight: 650, fontFamily: 'inherit' }}>
                    2 Mehndi PDF Card
                </Typography>
                <Typography sx={{ textAlign: 'left', fontSize: '0.875rem', fontWeight: 650, fontFamily: 'inherit' }}>
                    2 Sangeet PDF Card
                </Typography>
                <Typography sx={{ textAlign: 'left', fontSize: '0.875rem', fontWeight: 650, fontFamily: 'inherit' }}>
                    2 Shadi PDF Card
                </Typography>
                <Typography sx={{ textAlign: 'left', fontSize: '0.875rem', fontWeight: 650, fontFamily: 'inherit' }}>
                    2 Haldi Video Invitation Card
                </Typography>
                <Typography sx={{ textAlign: 'left', fontSize: '0.875rem', fontWeight: 650, fontFamily: 'inherit' }}>
                    2 Mehndi Video Invitation Card
                </Typography>
                <Typography sx={{ textAlign: 'left', fontSize: '0.875rem', fontWeight: 650, fontFamily: 'inherit' }}>
                    2 Sangeet Video Invitation Card
                </Typography>
                <Typography sx={{ textAlign: 'left', fontSize: '0.875rem', fontWeight: 650, fontFamily: 'inherit' }}>
                    2 Shadi Video Invitation Card
                </Typography>
                <Typography sx={{ textAlign: 'left', fontSize: '1rem', fontWeight: 450, fontFamily: 'inherit', color: 'red' }}>
                    Limited Slots Available. FIRST come FIRST get. Price Increasing soon. Hurry Up!
                </Typography>
                <Typography sx={{ textAlign: 'left', fontSize: '1rem', fontWeight: 450, fontFamily: 'inherit', color: 'red' }}>
                    Our WhatsApp Number: 87651 40002
                </Typography>

                <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 2, textAlign: 'left' }}>
                    Time left in current slot: <Box component="span" sx={{ color: 'red', fontWeight: '600' }}>{timeLeft}</Box>
                </Typography>

                {/* <Box sx={{ display: 'flex', justifyContent: 'left', marginTop: 2 }}>
                    <Rating value={5} readOnly />
                </Box> */}

                <Typography sx={{ textAlign: 'left', fontSize: '1rem', fontWeight: 450, fontFamily: 'inherit', color: 'black' }}>
                    Fill the details below to get the card and video
                </Typography>

                <Grid item xs={12} md={5} sx={{ padding: 1, marginTop: 1, borderRadius: '8px', backgroundColor: '#F0F4FA' }}>
                    <Box>
                        <Typography variant="body1" sx={{ marginTop: 2, marginBottom: 2, textAlign: 'left' }}>
                            Making Card For
                        </Typography>
                    </Box>
                    <Box>
                        <FormControl fullWidth required={true}>
                            <InputLabel id="500"></InputLabel>
                            <Select
                                labelId="500"
                                name="Card For"
                                id="500"
                                defaultValue="bride"
                                onChange={event => handleFieldChange({}, event)}
                                value={selectedSide}
                            >
                                <MenuItem key="1" value="bride" onClick={() => setSelectedSide("bride")}>
                                    Bride/Girl
                                </MenuItem>
                                <MenuItem key="2" value="groom" onClick={() => setSelectedSide("groom")}>
                                    Groom/Boy
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>

                {selectedSide === 'groom' ? (
                    <Grid item xs={12} md={5} sx={{ padding: 1, marginTop: 1, borderRadius: '8px', backgroundColor: '#E2EAF4' }}>
                        <Box>
                            <Typography variant="body1" sx={{ marginTop: 2, marginBottom: 2, textAlign: 'left' }}>
                                Date, Time and Venue
                            </Typography>
                        </Box>

                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        name="Haldi Event Date"
                                        id="1"
                                        label="Haldi Ceremony Date"
                                        placeholder="Haldi Ceremony Date"
                                        type="date"
                                        value={formData["Haldi Event Date"]}
                                        required={true}
                                        onChange={event => handleFieldChange("date", event)}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ marginTop: 1, marginBottom: 1 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        name="Haldi Event Time"
                                        id="2"
                                        label="Haldi Ceremony Time"
                                        placeholder="Haldi Ceremony Time"
                                        type="time"
                                        value={formData["Haldi Event Time"]}
                                        required={true}
                                        onChange={event => handleFieldChange("time", event)}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ marginTop: 1, marginBottom: 1 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        name="Haldi Venue"
                                        id="2"
                                        label="Haldi Venue"
                                        placeholder="Haldi Venue"
                                        type="text"
                                        value={formData["Haldi Venue"]}
                                        required={true}
                                        onChange={event => handleFieldChange("text", event)}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ marginTop: 1, marginBottom: 1 }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>

                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        name="Mehndi Event Date"
                                        id="1"
                                        label="Mehndi Ceremony Date"
                                        placeholder="Mehndi Ceremony Date"
                                        type="date"
                                        value={formData["Mehndi Event Date"]}
                                        required={true}
                                        onChange={event => handleFieldChange("date", event)}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ marginTop: 1, marginBottom: 1 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        name="Mehndi Event Time"
                                        id="2"
                                        label="Mehndi Ceremony Time"
                                        placeholder="Mehndi Ceremony Time"
                                        type="time"
                                        value={formData["Mehndi Event Time"]}
                                        required={true}
                                        onChange={event => handleFieldChange("time", event)}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ marginTop: 1, marginBottom: 1 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        name="Mehndi Venue"
                                        id="2"
                                        label="Mehndi Venue"
                                        placeholder="Mehndi Venue"
                                        type="text"
                                        value={formData["Mehndi Venue"]}
                                        required={true}
                                        onChange={event => handleFieldChange("text", event)}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ marginTop: 1, marginBottom: 1 }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>

                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        name="Sangeet Event Date"
                                        id="1"
                                        label="Sangeet Ceremony Date"
                                        placeholder="Sangeet Ceremony Date"
                                        type="date"
                                        value={formData["Sangeet Event Date"]}
                                        required={true}
                                        onChange={event => handleFieldChange("date", event)}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ marginTop: 1, marginBottom: 1 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        name="Sangeet Event Time"
                                        id="2"
                                        label="Sangeet Ceremony Time"
                                        placeholder="Sangeet Ceremony Time"
                                        type="time"
                                        value={formData["Sangeet Event Time"]}
                                        required={true}
                                        onChange={event => handleFieldChange("time", event)}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ marginTop: 1, marginBottom: 1 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        name="Sangeet Venue"
                                        id="2"
                                        label="Sangeet Venue"
                                        placeholder="Sangeet Venue"
                                        type="text"
                                        value={formData["Sangeet Venue"]}
                                        required={true}
                                        onChange={event => handleFieldChange("text", event)}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ marginTop: 1, marginBottom: 1 }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>

                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        name="Wedding Event Date"
                                        label="Wedding Ceremony Date"
                                        type="date"
                                        value={formData["Wedding Event Date"]}
                                        required={true}
                                        onChange={event => handleFieldChange("date", event)}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ marginTop: 1, marginBottom: 1 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        name="Wedding Event Time"
                                        label="Wedding Ceremony Time"
                                        type="time"
                                        value={formData["Wedding Event Time"]}
                                        required={true}
                                        onChange={event => handleFieldChange("time", event)}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ marginTop: 1, marginBottom: 1 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        name="Wedding Venue"
                                        label="Wedding Venue"
                                        placeholder='Wedding Venue'
                                        type="text"
                                        value={formData["Wedding Venue"]}
                                        required={true}
                                        onChange={event => handleFieldChange("text", event)}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ marginTop: 1, marginBottom: 1 }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>

                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        name="Tilak Event Date"
                                        label="Tilak Ceremony Date"
                                        type="date"
                                        value={formData["Tilak Event Date"]}
                                        required={true}
                                        onChange={event => handleFieldChange("date", event)}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ marginTop: 1, marginBottom: 1 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        name="Tilak Event Time"
                                        label="Tilak Ceremony Time"
                                        type="time"
                                        value={formData["Tilak Event Time"]}
                                        required={true}
                                        onChange={event => handleFieldChange("time", event)}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ marginTop: 1, marginBottom: 1 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        name="Tilak Venue"
                                        label="Tilak Venue"
                                        placeholder='Tilak Venue'
                                        type="text"
                                        value={formData["Tilak Venue"]}
                                        required={true}
                                        onChange={event => handleFieldChange("text", event)}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ marginTop: 1, marginBottom: 1 }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                ) : (
                    <Grid item xs={12} md={5} sx={{ padding: 1, marginTop: 1, borderRadius: '8px', backgroundColor: '#E2EAF4' }}>
                        <Box>
                            <Typography variant="body1" sx={{ marginTop: 2, marginBottom: 2, textAlign: 'left' }}>
                                Date, Time and Venue
                            </Typography>
                        </Box>

                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        name="Haldi Event Date"
                                        id="1"
                                        label="Haldi Ceremony Date"
                                        placeholder="Haldi Ceremony Date"
                                        type="date"
                                        value={formData["Haldi Event Date"]}
                                        required={true}
                                        onChange={event => handleFieldChange("date", event)}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ marginTop: 1, marginBottom: 1 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        name="Haldi Event Time"
                                        id="2"
                                        label="Haldi Ceremony Time"
                                        placeholder="Haldi Ceremony Time"
                                        type="time"
                                        value={formData["Haldi Event Time"]}
                                        required={true}
                                        onChange={event => handleFieldChange("time", event)}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ marginTop: 1, marginBottom: 1 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        name="Haldi Venue"
                                        id="2"
                                        label="Haldi Venue"
                                        placeholder="Haldi Venue"
                                        type="text"
                                        value={formData["Haldi Venue"]}
                                        required={true}
                                        onChange={event => handleFieldChange("text", event)}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ marginTop: 1, marginBottom: 1 }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>

                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        name="Mehndi Event Date"
                                        id="1"
                                        label="Mehndi Ceremony Date"
                                        placeholder="Mehndi Ceremony Date"
                                        type="date"
                                        value={formData["Mehndi Event Date"]}
                                        required={true}
                                        onChange={event => handleFieldChange("date", event)}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ marginTop: 1, marginBottom: 1 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        name="Mehndi Event Time"
                                        id="2"
                                        label="Mehndi Ceremony Time"
                                        placeholder="Mehndi Ceremony Time"
                                        type="time"
                                        value={formData["Mehndi Event Time"]}
                                        required={true}
                                        onChange={event => handleFieldChange("time", event)}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ marginTop: 1, marginBottom: 1 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        name="Mehndi Venue"
                                        id="2"
                                        label="Mehndi Venue"
                                        placeholder="Mehndi Venue"
                                        type="text"
                                        value={formData["Mehndi Venue"]}
                                        required={true}
                                        onChange={event => handleFieldChange("text", event)}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ marginTop: 1, marginBottom: 1 }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>

                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        name="Sangeet Event Date"
                                        id="1"
                                        label="Sangeet Ceremony Date"
                                        placeholder="Sangeet Ceremony Date"
                                        type="date"
                                        value={formData["Sangeet Event Date"]}
                                        required={true}
                                        onChange={event => handleFieldChange("date", event)}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ marginTop: 1, marginBottom: 1 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        name="Sangeet Event Time"
                                        id="2"
                                        label="Sangeet Ceremony Time"
                                        placeholder="Sangeet Ceremony Time"
                                        type="time"
                                        value={formData["Sangeet Event Time"]}
                                        required={true}
                                        onChange={event => handleFieldChange("time", event)}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ marginTop: 1, marginBottom: 1 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        name="Sangeet Venue"
                                        id="2"
                                        label="Sangeet Venue"
                                        placeholder="Sangeet Venue"
                                        type="text"
                                        value={formData["Sangeet Venue"]}
                                        required={true}
                                        onChange={event => handleFieldChange("text", event)}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ marginTop: 1, marginBottom: 1 }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>

                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        name="Wedding Event Date"
                                        label="Wedding Ceremony Date"
                                        type="date"
                                        value={formData["Wedding Event Date"]}
                                        required={true}
                                        onChange={event => handleFieldChange("date", event)}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ marginTop: 1, marginBottom: 1 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        name="Wedding Event Time"
                                        label="Wedding Ceremony Time"
                                        type="time"
                                        value={formData["Wedding Event Time"]}
                                        required={true}
                                        onChange={event => handleFieldChange("time", event)}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ marginTop: 1, marginBottom: 1 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        name="Wedding Venue"
                                        label="Wedding Venue"
                                        placeholder='Wedding Venue'
                                        type="text"
                                        value={formData["Wedding Venue"]}
                                        required={true}
                                        onChange={event => handleFieldChange("text", event)}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ marginTop: 1, marginBottom: 1 }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                )}




                <Grid item xs={12} md={5} sx={{ padding: 1, marginTop: 1, borderRadius: '8px', backgroundColor: '#E8F0FE' }}>
                    <Box>
                        <Typography variant="body1" sx={{ marginTop: 2, marginBottom: 2, textAlign: 'left' }}>
                            Bride (Girl) Details - English Only
                        </Typography>
                    </Box>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    fullWidth
                                    name="Bride Name"
                                    id="1"
                                    label="Bride/Girl Name"
                                    placeholder="Bride/Girl Name"
                                    type="string"
                                    value={formData["Bride Name"]}
                                    required={true}
                                    onChange={event => handleFieldChange("string_80", event)}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ marginTop: 1, marginBottom: 1 }}
                                    inputProps={{ maxLength: 30 }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="Bride Parent Name"
                                    id="2"
                                    label="Bride/Girl Parent Name"
                                    placeholder="Bride/Girl Parent Name"
                                    type="string"
                                    value={formData["Bride Parent Name"]}
                                    required={true}
                                    onChange={event => handleFieldChange("string_80", event)}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ marginTop: 1, marginBottom: 1 }}
                                    inputProps={{ maxLength: 80 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="Bride Address"
                                    id="2"
                                    label="Bride/Girl Address"
                                    placeholder="Bride/Girl Address"
                                    type="string"
                                    value={formData["Bride Address"]}
                                    required={true}
                                    onChange={event => handleFieldChange("string_80", event)}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ marginTop: 1, marginBottom: 1 }}
                                    inputProps={{ maxLength: 80 }}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                </Grid>



                <Grid item xs={12} md={5} sx={{ padding: 1, marginTop: 1, borderRadius: '8px', backgroundColor: '#EDF2F7' }}>
                    <Box>
                        <Typography variant="body1" sx={{ marginTop: 2, marginBottom: 2, textAlign: 'left' }}>
                            Groom (Boy) Details - English Only
                        </Typography>
                    </Box>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    fullWidth
                                    name="Groom Name"
                                    id="1"
                                    label="Groom/Boy Name"
                                    placeholder="Groom/Boy Name"
                                    type="string"
                                    value={formData["Groom Name"]}
                                    required={true}
                                    onChange={event => handleFieldChange("string_80", event)}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ marginTop: 1, marginBottom: 1 }}
                                    inputProps={{ maxLength: 30 }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="Groom Parent Name"
                                    id="2"
                                    label="Groom/Boy Parent Name"
                                    placeholder="Groom/Boy Parent Name"
                                    type="string"
                                    value={formData["Groom Parent Name"]}
                                    required={true}
                                    onChange={event => handleFieldChange("string_80", event)}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ marginTop: 1, marginBottom: 1 }}
                                    inputProps={{ maxLength: 80 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    name="Groom Address"
                                    id="2"
                                    label="Groom/Boy Address"
                                    placeholder="Groom/Boy Address"
                                    type="string"
                                    value={formData["Groom Address"]}
                                    required={true}
                                    onChange={event => handleFieldChange("string_80", event)}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ marginTop: 1, marginBottom: 1 }}
                                    inputProps={{ maxLength: 80 }}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                </Grid>



                <Grid item xs={12} md={5} sx={{ padding: 1, marginTop: 1, borderRadius: '8px', backgroundColor: '#F5F7FA' }}>
                    <Box>
                        <Typography variant="body1" sx={{ marginTop: 2, marginBottom: 2, textAlign: 'left' }}>
                            Your Family Details - English Only
                        </Typography>
                    </Box>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    fullWidth
                                    name="Family Members"
                                    id="1"
                                    label="Your Family Members"
                                    placeholder="Your Family Members"
                                    type="string"
                                    value={formData["Family Members"]}
                                    required={true}
                                    onChange={event => handleFieldChange("string_250", event)}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ marginTop: 1, marginBottom: 1 }}
                                    inputProps={{ maxLength: 220 }}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                </Grid>

                <Typography variant="h6" sx={{ marginTop: 2, marginBottom: 2, textAlign: 'left' }}>
                    Time left in current slot: <Box component="span" sx={{ color: 'green', fontWeight: '600' }}>{timeLeft}</Box>
                </Typography>


                <Button
                    variant="contained"
                    color="error"
                    sx={{ marginTop: 2, width: '100%' }}
                    onClick={() => handleSubmit()}
                    disabled={!isFormValid()}
                >
                    Submit Details
                </Button>

                <Grid item xs={12} md={5} sx={{ padding: 1, marginTop: 1, borderRadius: '8px', }}>
                    <Box>
                        <Typography variant="body1" sx={{ marginTop: 2, marginBottom: 2, textAlign: 'left' }}>
                            You can not modify the details once submitted. Please make sure all the details are correct.
                        </Typography>
                        <Typography variant="body1" sx={{ marginTop: 2, marginBottom: 2, textAlign: 'left' }}>
                            Upto 4hrs will be taken to create the card and video. You will get the download link on your email or whatsapp or you can download from the website by login with the above mobile number.
                        </Typography>
                        <Typography variant="body1" sx={{ marginTop: 2, marginBottom: 2, textAlign: 'left' }}>
                            Our designs are fixed and you can not change the design once submitted. If you want to change the design, you need to pay again. Branding can not be removed. Please see sample above.
                        </Typography>
                    </Box>
                </Grid>
            </>
              {/* Description Section */}
          <Box mb={4}>
            <Typography variant="h6" gutterBottom>
              Description
            </Typography>
            <Typography variant="body1" color="initial" sx={{ textAlign: 'left', fontWeight: 750, fontFamily: 'inherit' }}> 🔶Specification of Creative engagement invitation card template:</Typography>
            <table
              style={{
                width: '100%',
                borderCollapse: 'collapse',
                marginTop: '10px',
                border: '1px solid #ddd',
              }}
            >
              <tbody>
                <tr style={{ borderBottom: '1px solid #ddd' }}>
                  <td
                    style={{
                      padding: '8px',
                      textAlign: 'left',
                      fontWeight: 'bold',
                      borderRight: '1px solid #ddd',
                    }}
                  >
                    Description:
                  </td>
                  <td style={{ padding: '8px', textAlign: 'left' }}>
                    Creative invitation card design
                  </td>
                </tr>
                <tr style={{ borderBottom: '1px solid #ddd' }}>
                  <td
                    style={{
                      padding: '8px',
                      textAlign: 'left',
                      fontWeight: 'bold',
                      borderRight: '1px solid #ddd',
                    }}
                  >
                    Delivery Timeline:
                  </td>
                  <td style={{ padding: '8px', textAlign: 'left' }}>
                    Download option available after successful payment
                  </td>
                </tr>
                <tr style={{ borderBottom: '1px solid #ddd' }}>
                  <td
                    style={{
                      padding: '8px',
                      textAlign: 'left',
                      fontWeight: 'bold',
                      borderRight: '1px solid #ddd',
                    }}
                  >
                    Ideal For:
                  </td>
                  <td style={{ padding: '8px', textAlign: 'left' }}>
                    Share Indian engagement invitation template designs on WhatsApp, Facebook, or Print.
                  </td>
                </tr>
                <tr style={{ borderBottom: '1px solid #ddd' }}>
                  <td
                    style={{
                      padding: '8px',
                      textAlign: 'left',
                      fontWeight: 'bold',
                      borderRight: '1px solid #ddd',
                    }}
                  >
                    Delivery Format:
                  </td>
                  <td style={{ padding: '8px', textAlign: 'left' }}>
                    PDF or Video Depending upon the design you have choosen
                  </td>
                </tr>
                <tr style={{ borderBottom: '1px solid #ddd' }}>
                  <td
                    style={{
                      padding: '8px',
                      textAlign: 'left',
                      fontWeight: 'bold',
                      borderRight: '1px solid #ddd',
                    }}
                  >
                    Changes:
                  </td>
                  <td style={{ padding: '8px', textAlign: 'left' }}>
                    Please fill your details carefully. But in case if you want to make changes, after submit & Payment you will be allowed to make changes 1 time.
                  </td>
                </tr>
                <tr style={{ borderBottom: '1px solid #ddd' }}>
                  <td
                    style={{
                      padding: '8px',
                      textAlign: 'left',
                      fontWeight: 'bold',
                      borderRight: '1px solid #ddd',
                    }}
                  >
                    Payment Method:
                  </td>
                  <td style={{ padding: '8px', textAlign: 'left' }}>
                    You can make payment via Paytm, Google Pay, PhonePe, UPI, Debit Card, Credit Card, Net Banking, UPI, etc.
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>

        </Box >
    );
}

export default BundleCodeDetails;
