export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const SET_CRASHES = "SET_CRASHES";
export const CLEAR_CRASHES = "CLEAR_CRASHES";

export const SHOW_OTP_INPUT = 'SHOW_OTP_INPUT';
export const LOG_IN = "LOG_IN";
export const REGISTER = "REGISTER";
export const LOG_OUT = "LOG_OUT";

export const VERIFY_OTP = "VERIFY_OTP";

export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS';
export const FETCH_USER_DETAILS_SUCCESS = 'FETCH_USER_DETAILS_SUCCESS';

export const FETCH_FAQ_DATA_REQUEST ='FETCH_FAQ_DATA_REQUEST';
export const FETCH_FAQ_DATA_SUCCESS ='FETCH_FAQ_DATA_SUCCESS';
export const FETCH_FAQ_DATA_FAILURE ='FETCH_FAQ_DATA_FAILURE';

export const REPORT_ISSUE_REQUEST = 'REPORT_ISSUE_REQUEST';
export const REPORT_ISSUE_SUCCESS = 'REPORT_ISSUE_SUCCESS';
export const REPORT_ISSUE_FAILURE = 'REPORT_ISSUE_FAILURE';

export const CREATE_REPORT_ISSUE_REQUEST = 'CREATE_REPORT_ISSUE_REQUEST';
export const CREATE_REPORT_ISSUE_SUCCESS = 'CREATE_REPORT_ISSUE_SUCCESS';
export const CREATE_REPORT_ISSUE_FAILURE = 'CREATE_REPORT_ISSUE_FAILURE';

export const FETCH_CARDS_FOR_CUSTOMER_REQUEST = 'FETCH_CARDS_FOR_CUSTOMER_REQUEST';
export const FETCH_CARDS_FOR_CUSTOMER_SUCCESS = 'FETCH_CARDS_FOR_CUSTOMER_SUCCESS';
export const FETCH_CARDS_FOR_CUSTOMER_FAILURE = 'FETCH_CARDS_FOR_CUSTOMER_FAILURE';

export const CREATE_CARDS_FOR_CUSTOMER_REQUEST = 'CREATE_CARDS_FOR_CUSTOMER_REQUEST';
export const CREATE_CARDS_FOR_CUSTOMER_SUCCESS = 'CREATE_CARDS_FOR_CUSTOMER_SUCCESS';
export const CREATE_CARDS_FOR_CUSTOMER_FAILURE = 'CREATE_CARDS_FOR_CUSTOMER_FAILURE';

export const GET_ALL_CARD_SUGGESTION_REQUEST = 'GET_ALL_CARD_SUGGESTION_REQUEST';
export const GET_ALL_CARD_SUGGESTION_SUCCESS = 'GET_ALL_CARD_SUGGESTION_SUCCESS';
export const GET_ALL_CARD_SUGGESTION_FAILURE = 'GET_ALL_CARD_SUGGESTION_FAILURE';

export const GET_ALL_CARDS_DATA_REQUEST = 'GET_ALL_CARDS_DATA_REQUEST';
export const GET_ALL_CARDS_DATA_SUCCESS = 'GET_ALL_CARDS_DATA_SUCCESS';
export const GET_ALL_CARDS_DATA_FAILURE = 'GET_ALL_CARDS_DATA_FAILURE';

export const ADD_COMPLETE_PROFILE_DETAILS_REQUEST = 'ADD_COMPLETE_PROFILE_DETAILS_REQUEST';
export const ADD_COMPLETE_PROFILE_DETAILS_SUCCESS = 'ADD_COMPLETE_PROFILE_DETAILS_SUCCESS';
export const ADD_COMPLETE_PROFILE_DETAILS_FAILURE = 'ADD_COMPLETE_PROFILE_DETAILS_FAILURE';

export const GET_ALL_PROFILES_REQUEST = 'GET_ALL_PROFILES_REQUEST';
export const GET_ALL_PROFILES_SUCCESS = 'GET_ALL_PROFILES_SUCCESS';
export const GET_ALL_PROFILES_FAILURE = 'GET_ALL_PROFILES_FAILURE';

export const ADD_PROFILE_DETAILS_REQUEST = 'ADD_PROFILE_DETAILS_REQUEST';
export const ADD_PROFILE_DETAILS_SUCCESS = 'ADD_PROFILE_DETAILS_SUCCESS';
export const ADD_PROFILE_DETAILS_FAILURE = 'ADD_PROFILE_DETAILS_FAILURE';

export const UPDATE_PROFILE_DETAILS_REQUEST = 'UPDATE_PROFILE_DETAILS_REQUEST';
export const UPDATE_PROFILE_DETAILS_SUCCESS = 'UPDATE_PROFILE_DETAILS_SUCCESS';
export const UPDATE_PROFILE_DETAILS_FAILURE = 'UPDATE_PROFILE_DETAILS_FAILURE';

export const GET_SINGLE_PROFILE_DETAILS_REQUEST = 'GET_SINGLE_PROFILE_DETAILS_REQUEST';
export const GET_SINGLE_PROFILE_DETAILS_SUCCESS = 'GET_SINGLE_PROFILE_DETAILS_SUCCESS';
export const GET_SINGLE_PROFILE_DETAILS_FAILURE = 'GET_SINGLE_PROFILE_DETAILS_FAILURE';

export const DELETE_PROFILE_REQUEST = 'DELETE_PROFILE_REQUEST';
export const DELETE_PROFILE_SUCCESS = 'DELETE_PROFILE_SUCCESS';
export const DELETE_PROFILE_FAILURE = 'DELETE_PROFILE_FAILURE';

export const GET_BUNDLES_REQUEST = 'GET_BUNDLES_REQUEST';
export const GET_BUNDLES_SUCCESS = 'GET_BUNDLES_SUCCESS';
export const GET_BUNDLES_FAILURE = 'GET_BUNDLES_FAILURE';





