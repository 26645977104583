import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteProfileData, getAllProfilesData } from "../../redux/actions/multiProfileActions";
import { useSelector } from "react-redux";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import MailOutlineIcon from '@mui/icons-material/MailOutline';


const MyProfiles = () => {
    const [profiles, setProfiles] = useState([
        { name: "Personal", avatar: "https://via.placeholder.com/100", path: "personal" },
        { name: "Company", avatar: "https://via.placeholder.com/100", path: "company" },
        { name: "Small Business", avatar: "https://via.placeholder.com/100", path: "small_business" },
        { name: "Organization", avatar: "https://via.placeholder.com/100", path: "organization" },
        { name: "Profession", avatar: "https://via.placeholder.com/100", path: "profession" },
        { name: "School", avatar: "https://via.placeholder.com/100", path: "school" },
        { name: "College", avatar: "https://via.placeholder.com/100", path: "college" },
        { name: "Coaching_institute", avatar: "https://via.placeholder.com/100", path: "coaching_institute" },
        { name: "Hospital", avatar: "https://via.placeholder.com/100", path: "hospital" },
        { name: "Clinic", avatar: "https://via.placeholder.com/100", path: "clinic" },
    ]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null);
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [editProfileId, setEditProfileId] = useState();
    const [deleteProfileId, setDeleteProfileId] = useState();

    const handleActionMenuClick = (event, id) => {
        setActionMenuAnchorEl(event.currentTarget);
        setEditProfileId(id);
        setDeleteProfileId(id);
    };

    const handleActionMenuClose = () => {
        setActionMenuAnchorEl(null);
        setEditProfileId(null);
        setDeleteProfileId(null);
    };
    //fetch profile data 
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const profileData = useSelector((state) => state?.multiProfile?.profileData?.data);
    console.log("Profile Data", profileData);

    useEffect(() => {
        const fetchAllProfiles = async () => {
            setLoading(true);
            await dispatch(getAllProfilesData());
            setLoading(false);
        };
        fetchAllProfiles();
    }, [dispatch])

    const navigate = useNavigate();

    const handleNavigation = (path_url) => {
        navigate(`/dashboard/create/${path_url}`);
    };

    // delete profile 
    const [open, setOpen] = useState(false);

    const handleDeleteOpen = () => {
        setOpen(true);
    };
    const handleDeleteClose = () => {
        setOpen(false);
    }

    const handleDelete = async () => {
        const deletedData = { profileId: deleteProfileId }
        await dispatch(deleteProfileData(deletedData));
        setOpen(false);
    }

    // picture upload
    const [profilePic, setProfilePic] = useState(null);
    const [coverPic, setCoverPic] = useState(null);

    const handleProfilePicChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setProfilePic(URL.createObjectURL(file));
        }
    };

    const handleCoverPicChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setCoverPic(URL.createObjectURL(file));
        }
    };

    return (
        <>
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: { xs: '300px', sm: '350px' },
                    mb: 3
                }}
            >
                <Box
                    component="div"
                    sx={{
                        backgroundImage: `url(${coverPic || 'https://via.placeholder.com/1000x200'})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: { xs: '150px', sm: '200px' },
                        width: '100%',
                        cursor: 'pointer',
                    }}
                    onClick={() => document.getElementById('cover-upload').click()}
                />
                <input
                    type="file"
                    id="cover-upload"
                    style={{ display: 'none' }}
                    onChange={handleCoverPicChange}
                />

                {/* Profile Avatar */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: { xs: '50%', sm: '160px' },
                        left: { xs: '50%', sm: '20px' },
                        transform: { xs: 'translate(-50%, -50%)', sm: 'none' },
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <Avatar
                        alt="User Full Name"
                        src={profilePic || 'https://via.placeholder.com/150'}
                        sx={{
                            width: { xs: 150, sm: 150 },
                            height: { xs: 150, sm: 150 },
                            border: '2px solid white',
                            cursor: 'pointer',
                        }}
                        onClick={() => document.getElementById('profile-upload').click()}
                    />
                    <input
                        type="file"
                        id="profile-upload"
                        style={{ display: 'none' }}
                        onChange={handleProfilePicChange}
                    />

                    <Box sx={{ ml: 2 }}>
                        <Typography variant="h6" fontSize={{ xs: '16px', sm: '20px' }}>User Full Name</Typography>
                    </Box>
                </Box>


                <Button
                    variant="outlined"
                    sx={{
                        position: 'absolute',
                        bottom: { xs: '10px', sm: '70px' },
                        right: { xs: '50%', sm: '10px' },
                        transform: { xs: 'translateX(50%)', sm: 'none' },
                        width: { xs: '80%', sm: 'auto' }
                    }}
                >
                    Change Login Password
                </Button>
            </Box>
            <div style={{ position: "relative", padding: "20px", marginBottom: 50 }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: 'space-between',
                        position: 'relative',
                    }}
                >
                    <Typography
                        variant="span"
                        component="div"
                        sx={{
                            position: { xs: 'relative', md: 'absolute' },
                            top: { xs: 'auto', md: 20 },

                            width: { xs: '100%', md: '50%' },
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                        }}
                    >
                        Following are the list of profile that can be used to generate profile
                    </Typography>

                    <Button
                        variant="contained"
                        onClick={handleMenuClick}
                        sx={{
                            position: { xs: 'relative', md: 'absolute' },
                            top: { xs: 'auto', md: 5 },
                            right: 2,
                            marginTop: { xs: '10px', md: '0' },
                            width: { xs: '50%', md: 'auto' },
                        }}
                    >
                        Add Profile
                    </Button>
                </Box>

                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxWidth: '400px',
                            width: '100%',
                            maxHeight: '300px',
                            overflowY: 'auto',
                        },
                    }}
                >
                    <Box sx={{ padding: '10px' }}>
                        <Grid container spacing={1}>
                            {profiles.map((profile, index) => (
                                <Grid item key={index} xs={4} >
                                    <MenuItem onClick={() => handleNavigation(profile.path)} sx={{ padding: '0px', display: 'flex', justifyContent: 'center' }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                textAlign: 'center',
                                                m: 1,
                                            }}
                                        >
                                            <Avatar
                                                src={profile.avatar}
                                                alt={profile.name}
                                                sx={{ width: 60, height: 60 }}
                                            />
                                            <Typography sx={{ mt: 1, fontSize: '0.9rem' }}>
                                                {profile.name}
                                            </Typography>
                                        </Box>
                                    </MenuItem>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Menu>

            </div>

            <Grid container spacing={2}>
                <Grid item xs={12} >
                    {loading ? (

                        <Box sx={{ textAlign: 'center', padding: '20px' }}>
                            <Typography>Please wait, while fetching data...</Typography>
                        </Box>
                    ) : (
                        profileData?.records?.map((data, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 2,
                                    padding: '20px',
                                    background: 'white',
                                    borderRadius: '16px',
                                    marginBottom: '20px',
                                    position: 'relative',
                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                                }}
                            >
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography
                                        sx={{
                                            background: '#4F4F25',
                                            color: 'white',
                                            padding: '5px 10px',
                                            borderRadius: '8px',
                                        }}
                                    >
                                        Profile Type: {data?.profileType}
                                    </Typography>

                                    <Typography sx={{}}>#{data?.profileId?.slice(0, 5)}</Typography>

                                    <IconButton
                                        aria-controls="action-menu"
                                        aria-haspopup="true"

                                        onClick={(event) => handleActionMenuClick(event, data?.profileId)}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>

                                    {profiles.map((profie, i) => (
                                        <Menu
                                            id="action-menu"
                                            keepMounted
                                            key={i}
                                            anchorEl={actionMenuAnchorEl}
                                            open={Boolean(actionMenuAnchorEl) && editProfileId === data?.profileId && deleteProfileId === data?.profileId}
                                            onClose={handleActionMenuClose}
                                        >

                                            <MenuItem onClick={() => navigate(`/dashboard/edit/${data.profileId}`)}>
                                                <ListItemIcon>
                                                    <EditIcon />
                                                </ListItemIcon>
                                                Edit
                                            </MenuItem>
                                            <MenuItem onClick={handleDeleteOpen}>
                                                <ListItemIcon sx={{
                                                    color: 'red'
                                                }}>
                                                    <DeleteIcon />
                                                </ListItemIcon>
                                                Delete
                                            </MenuItem>
                                        </Menu>
                                    ))}

                                </Box>


                               
                                {data?.profileType !== 'personal' && (
                                    <Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: { xs: 'column', sm: 'row' }, 
                                                justifyContent: { sm: 'space-between', xs: 'center' }, 
                                                alignItems: { sm: 'center', xs: 'center' }, 
                                            }}
                                        >
                                            {/* Avatar Section */}
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    mb: { xs: 2, sm: 0 }, 
                                                }}
                                            >
                                                <Box
                                                    component="img"
                                                    src="/path/to/logo.png" 
                                                    alt="Logo"
                                                    sx={{
                                                        borderRadius: '50%',
                                                        width: '80px',
                                                        height: '80px',
                                                        backgroundColor: '#f0f0f0',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    textAlign: { xs: 'center', sm: 'center' }, 
                                                    flex: 1,
                                                    mt: { xs: 2, sm: 0 }, 
                                                }}
                                            >
                                               
                                                <Typography variant="h5" sx={{ mb: 1 }}>Address</Typography>

                                               
                                                <Typography>
                                                    {data?.address}, {data?.district}, {data?.state} - {data?.pincode}
                                                </Typography>
                                            </Box>
                                        </Box>

                                    </Box>
                                )}
                                
                                {(data?.profileType === 'company' || data?.profileType === 'small_business' || data?.profileType === 'organization') && (
                                    <Box>
                                        <Box sx={{ display: 'flex',flexDirection: { xs: 'column', sm: 'row' },  justifyContent: 'space-between', marginTop: '10px',alignItems: { sm: 'center', xs: 'center' },  }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <WhatsAppIcon sx={{ color: '#25D366', marginRight: '8px' }} />
                                                <Typography>{data?.mobile1}</Typography>
                                            </Box>
                                            {data?.mobile2 && (
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <WhatsAppIcon sx={{ color: '#25D366', marginRight: '8px' }} />
                                                    <Typography>{data?.mobile2}</Typography>
                                                </Box>
                                            )}
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <MailOutlineIcon sx={{ color: 'red', marginRight: '8px' }} />
                                                <Typography>{data?.email}</Typography>
                                            </Box>
                                            <Typography variant="body2" sx={{}}>
                                                Owner: {data?.owner}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        ))
                    )}
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleDeleteClose}>
                <DialogTitle>Delete Profile</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this profile
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    );
};

export default MyProfiles;