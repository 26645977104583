import { Box, Button, Card, CardContent, CardMedia, Grid, Rating, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function CardSample() {
    const Samples = [
        {
            bundleName: 'starter', BundleCode: 'Wedding11', originalAmount: '249', offerAmount: '11',
            bundleNumber: 'Bundle 1', url: 'https://innovators.blr1.cdn.digitaloceanspaces.com/sample/video_sample_1.mp4', path: 'wedding11-form'
        },
        {
            bundleName: 'starter', BundleCode: 'Wedding49', originalAmount: '499', offerAmount: '51',
            bundleNumber: 'Bundle 2', url: 'https://innovators.blr1.cdn.digitaloceanspaces.com/sample/video_sample_2.mp4', path: 'wedding49-form'
        },
        {
            bundleName: 'Basic', BundleCode: 'Wedding199', originalAmount: '799', offerAmount: '201',
            bundleNumber: 'Bundle 3', url: 'https://innovators.blr1.cdn.digitaloceanspaces.com/sample/video_sample_3.mp4', path: 'wedding199-form'
        },
        {
            bundleName: 'starter', BundleCode: 'Wedding11', originalAmount: '249', offerAmount: '11',
            bundleNumber: 'Bundle 1', url: 'https://innovators.blr1.cdn.digitaloceanspaces.com/sample/card_sample_1.pdf', path: 'wedding11-form'
        },
        {
            bundleName: 'Premium', BundleCode: 'Wedding799', originalAmount: '4487', offerAmount: '801',
            bundleNumber: 'Bundle 5', url: 'https://innovators.blr1.cdn.digitaloceanspaces.com/sample/card_sample_2.pdf', path: 'wedding799-form'
        },
        {
            bundleName: 'Pro', BundleCode: 'Wedding999', originalAmount: '6384', offerAmount: '1501',
            bundleNumber: 'Bundle 6', url: 'https://innovators.blr1.cdn.digitaloceanspaces.com/sample/card_sample_3.pdf', path: 'wedding999-form'
        },
        {
            bundleName: 'Premium', BundleCode: 'Wedding499', originalAmount: '1499', offerAmount: '501',
            bundleNumber: 'Bundle 4', url: 'https://innovators.blr1.cdn.digitaloceanspaces.com/sample/card_sample_4.pdf', path: 'wedding499-form'
        },
    ];

    const navigate = useNavigate();

    const handleNavigation = (path_url) => {
        navigate(`/${path_url}`);
    };

    const handleDownload = async (card) => {
        if (!card?.url) return;

        try {
            const response = await fetch(card.url);
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);

            const urlParts = card.url.split('.');
            const extension = urlParts[urlParts.length - 1];

            const filename = card.title + `final.${extension}`;

            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Download failed:", error);
        }
    };

    return (
        <div>
            <Box
                sx={{
                    textAlign: 'left',
                    alignItems: 'left',
                    borderRadius: '15px',
                    marginTop: 2,
                    marginLeft: 4,
                }}
            >
                <Button
                    variant='outlined'
                    sx={{
                        color: '#616161',
                        borderColor: '#e0e0e0',
                        backgroundColor: '#eeeeee',
                        borderRadius: 4
                    }}
                    onClick={() => navigate(`/bundle-page`)}
                >
                    Back to Bundle Page
                </Button>
            </Box>

            <Grid container spacing={3} sx={{ padding: 4 }}>

                {Samples && Samples.length > 0 ? (
                    Samples.map((bundle, index) => (
                        <Grid item xs={6} sm={6} md={2} key={index} sx={{ margin: 'auto' }}>
                            <Card sx={{ maxWidth: '100%', overflow: 'hidden' }}>
                                {bundle.url.endsWith(".pdf") ? (
                                    <embed
                                        src={bundle.url}
                                        type="application/pdf"
                                        width="100%"
                                        height="280px"
                                        style={{ objectFit: 'cover', overflow: 'hidden' }}
                                    />
                                ) : bundle.url.endsWith(".mp4") ? (
                                    <video
                                        width="100%"
                                        height="280"
                                        controls
                                        style={{ objectFit: 'cover', overflow: 'hidden', display: 'block' }}
                                    >
                                        <source src={bundle.url} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                ) : (
                                    <CardMedia
                                        component="img"
                                        height="280"
                                        image={bundle.url}
                                        alt="Bundle preview"
                                        sx={{
                                            objectFit: 'cover',
                                            width: '100%',
                                            height: '280px',
                                            overflow: 'hidden',
                                            display: 'block'
                                        }}
                                    />
                                )}

                                <CardContent>
                                    <Button
                                        variant='outlined'
                                        onClick={() => handleDownload(bundle)}
                                        sx={{ textTransform: 'none' }}
                                    >
                                        Download
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        
                                        sx={{ textTransform: 'none' }}
                                        onClick={() => {
                                            const link = document.createElement('a');
                                            link.href = bundle.url;
                                            link.download = bundle.url || 'download';
                                            link.target = '_blank';
                                            link.click();
                                        }}
                                    >
                                        View
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                ) : (
                    <Typography>No bundles available</Typography>
                )}
            </Grid>
        </div>
    );
}

export default CardSample;
