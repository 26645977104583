import React, { useState } from 'react';
import { Button, TextField, Container, Typography, Box, CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { verifyOtp } from '../redux/actions/authActions';

const VerifyOtp = () => {
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { referenceId, mobileNumber, tenant } = location.state || {};

  const handleSubmitOtp = async () => {
    setLoading(true);
    setError('');
    const otpData = { OTP: otp, RefId: referenceId, mobile: mobileNumber, tenant };
    try {
      const response = await dispatch(verifyOtp(otpData));
      if (response && response.status === 'success') {
        navigate('/dashboard/my-cards');
      } else {
        setError('OTP verification failed. Please try again.');
      }
    } catch (err) {
      setError('OTP verification failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 8 }}>
        <Typography component="h1" variant="h5">Verify OTP</Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          label="OTP"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          autoFocus
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSubmitOtp}
          disabled={loading}
          sx={{ marginBottom: '16px' }}
        >
          {loading ? <CircularProgress size={24} /> : 'Sign in with OTP'}
        </Button>
        {error && <Typography color="error">{error}</Typography>}
      </Box>
    </Container>
  );
};

export default VerifyOtp;
