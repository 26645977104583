import * as actionTypes from "../actionTypes.js";

const initialState = {
    engagement: { getAllCardSuggestion: [], loading: false, error: null, },
    wedding: { getAllCardSuggestion: [], loading: false, error: null, },
    birthday: { getAllCardSuggestion: [], loading: false, error: null, },
}

const getAllCardSuggestionReducer = (state = initialState, action) => {
    const { group_path, payload } = action;
    switch (action.type) {
        case actionTypes.GET_ALL_CARD_SUGGESTION_REQUEST:
            return {
                ...state,
                [group_path]: {
                    ...state[group_path],
                    loading: true,
                    error: null,
                },
            };
        case actionTypes.GET_ALL_CARD_SUGGESTION_SUCCESS:
            return {
                ...state,
                [group_path]: {
                    ...state[group_path],
                    getAllCardSuggestion: payload,
                    loading: false,
                    error: null
                },
            };
        case actionTypes.GET_ALL_CARD_SUGGESTION_FAILURE:
            return {
                ...state,
                [group_path]: {
                    ...state[group_path],
                    loading: false,
                    error: payload
                },
            };
        default:
            return state
    }

};
export default getAllCardSuggestionReducer;