import * as actionTypes from "../actionTypes.js";

const initialState ={
    cardForCustomerdata: [],
    loading: false,
    error: null,
}

const cardForCustomerReducer = (state= initialState, action) => {

    switch(action.type){
        case actionTypes.FETCH_CARDS_FOR_CUSTOMER_REQUEST: 
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.FETCH_CARDS_FOR_CUSTOMER_SUCCESS: 
            return{
                ...state,
                cardForCustomerdata: action.payload,
                loading: false,
                error: null,
            };
        case actionTypes.FETCH_CARDS_FOR_CUSTOMER_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.CREATE_CARDS_FOR_CUSTOMER_REQUEST:
            return{
                ...state,
                loading: true,
                cardForCustomerdata: null
            };
        case actionTypes.CREATE_CARDS_FOR_CUSTOMER_SUCCESS:
            state.cardForCustomerdata.data.rows = [...state.cardForCustomerdata.data.rows, action.payload]
            console.log("Inside Reducer", state.cardForCustomerdata);
            return{
                ...state,
                cardForCustomerdata: state.cardForCustomerdata,
                loading: false,
                error: null,
            };
        case actionTypes.CREATE_CARDS_FOR_CUSTOMER_FAILURE:
            return{
                ...state,
                loading: false,
                error: null,
            };
        default:
            return state;
    }

};

export default cardForCustomerReducer;