import { Box, Card, CardContent, CardMedia, Grid, Rating, Typography } from '@mui/material';
import React from 'react';
import bundleImage from '../../assets/icons/bundleImage.png';
import { useNavigate } from 'react-router-dom';
import bundle_sample from '../../assets/icons/bundle_sample.png';
import bundle_img1 from '../../assets/icons/bundle_img1.png';

function BundlesPage() {

    const bundles = [
        {
            bundleName: 'starter', BundleCode: 'Wedding11 ', originalAmount: '249', offerAmount: '11', description: '1 Wedding PDF E-invite ',
            bundleNumber: 'Bundle 1 ', image: 'https://innovators.blr1.cdn.digitaloceanspaces.com/static/ndd1.png', path: 'wedding11-form'
        },
        {
            bundleName: 'starter', BundleCode: 'Wedding49 ', originalAmount: '499', offerAmount: '51', description: '1 Wedding Video E-invite  ',
            bundleNumber: 'Bundle 2 ', image: 'https://innovators.blr1.cdn.digitaloceanspaces.com/static/ndd2.png', path: 'wedding49-form'
        },
        {
            bundleName: 'Basic', BundleCode: 'Wedding199 ', originalAmount: '799', offerAmount: '201', description: '1 Premium Video E-invite  ',
            bundleNumber: 'Bundle 3 ', image: 'https://innovators.blr1.cdn.digitaloceanspaces.com/static/ndd3.png', path: 'wedding199-form'
        },
        {
            bundleName: 'Premium ', BundleCode: 'Wedding499 ', originalAmount: '1499', offerAmount: '501',
            description: '1 Halid PDF, 1 Mehndi PDF, 1 Sangeet PDF, 1 Wedding PDF, 1 Tilak PDF (for Groom Only), 1 Combined Haldi, Mehndi, Sangeet, Wedding Video ',
            bundleNumber: 'Bundle 4 ', image: 'https://innovators.blr1.cdn.digitaloceanspaces.com/static/ndd4.png', path: 'wedding499-form'
        },
        {
            bundleName: 'Premium', BundleCode: 'Wedding799 ', originalAmount: '4487', offerAmount: '801',
            description: '2 Haldi PDF, 2 Mehndi PDF, 2 Sangeet PDF, 2 Wedding PDF, 2 Tilak PDF (for Groom Only), 3 Combined Haldi, Mehndi, Sangeet, Wedding Video E-invite, 2 save the date Video E-Invite.   '
            , bundleNumber: 'Bundle 5 ', image: 'https://innovators.blr1.cdn.digitaloceanspaces.com/static/ndd5.png', path: 'wedding799-form'
        },
        {
            bundleName: 'Pro', BundleCode: 'Wedding999 ', originalAmount: '6384', offerAmount: '1501',
            description: '2 Haldi PDF, 2 Mehndi PDF, 2 Sangeet PDF, 2 Wedding PDF, 2 Tilak PDF (for Groom Only), 1 Haldi Video E-invite, 1 Mehndi Video E-invite, 1 Sangeet Video E-invite, 1 Wedding Video E-Invite, 4 Combined Haldi, Mehndi, Sangeet, Tilak (for Groom Only), Wedding Video E-invite  '
            , bundleNumber: 'Bundle 6 ', image: 'https://innovators.blr1.cdn.digitaloceanspaces.com/static/ndd6.png', path: 'wedding999-form'
        },
    ]
    const navigate = useNavigate();

    const handleNavigation = (path_url) => {
        navigate(`/${path_url}`);
    };
    return (
        <div>
            <Box
                sx={{
                    textAlign: 'center',
                    alignItems: 'center',
                    borderRadius: '15px',
                    marginTop: 1
                }}
            >
                <img
                    src={bundle_sample}
                    alt="Wedding"
                    style={{ width: '100%', maxWidth: '600px' }}
                    onClick={() => navigate('/card-sample')}
                />
            </Box>

            <Box
                sx={{
                    textAlign: 'center',
                    alignItems: 'center',
                    borderRadius: '15px',
                    marginTop: 2
                }}
            >
                <img
                    src={bundle_img1}
                    alt="Wedding"
                    style={{ width: '100%', maxWidth: '600px' }}
                    onClick={() => navigate('/bundle-page')}
                />
            </Box>
            <Grid container spacing={3} sx={{  padding: 4 }}>
                {bundles && bundles.length > 0 ? (
                    bundles.map((bundle, index) => (
                        <Grid item xs={6} sm={6} md={2} key={index} sx={{ margin: 'auto' }}>
                            <Card sx={{ maxWidth: '100%' }}>

                                <CardMedia
                                    component="img"
                                    image={bundle.image}
                                    style={{ width: '100%', height: '100%' }}
                                    onClick={() => handleNavigation(bundle.path)}
                                />
                                <CardContent>
                                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'flex-start', md: 'center' }, gap: 1, marginBottom: { md: 1 } }}>
                                        <Box
                                            bgcolor="#d32f2f"
                                            color="white"
                                            px={0.5}
                                            py={0.5}
                                            borderRadius="4px"
                                            display="inline-block"
                                            sx={{ fontSize: '1rem' }}
                                        >
                                            {Math.round(((bundle?.originalAmount - bundle?.offerAmount) / bundle?.originalAmount) * 100)}% off
                                        </Box>
                                        <Typography sx={{ fontSize: '0.825rem', color: 'red', fontWeight: 'bold' }}>
                                            Dev Diwali Sale
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5, flexDirection: { xs: 'column', md: 'row' } }}>
                                        <Typography variant="h6" color="textPrimary" fontWeight="bold" sx={{ fontSize: '1.25rem', lineHeight: 1 }}>
                                            {Math.floor(bundle.offerAmount) === 0 ? 'Free' : `₹${Math.floor(bundle.offerAmount)}`}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" sx={{ textDecoration: 'line-through', fontSize: '0.875rem' }}>
                                            M.R.P: ₹{bundle?.originalAmount.toLocaleString()}
                                        </Typography>
                                    </Box>
                                    <Typography sx={{ fontSize: '1rem' }}>
                                        {bundle.bundleName}
                                    </Typography>
                                    <Rating value={5} readOnly />
                                </CardContent>

                            </Card>
                        </Grid>
                    ))
                ) : (
                    <Typography>No bundles available</Typography>
                )}
            </Grid>

        </div>
    )
}

export default BundlesPage