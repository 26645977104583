import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {thunk} from 'redux-thunk';  // Correct import for thunk
import rootReducer from './reducers';

// Persist config
const persistConfig = {
  key: 'root',
  storage,
};

// Persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create store
const store = createStore(persistedReducer, applyMiddleware(thunk));

// Create persistor
const persistor = persistStore(store);

export  { store, persistor };
