import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';

export const fetchFaqDataRequest = () => ({
    type: actionTypes.FETCH_FAQ_DATA_REQUEST,
});

export const fetchFaqDataSuccess = (data) => ({
    type: actionTypes.FETCH_FAQ_DATA_SUCCESS,
    payload: data,
});

export const fetchFaqDataFailure = (error) => ({
    type: actionTypes.FETCH_FAQ_DATA_FAILURE,
    payload: error,
});

export const fetchFaqData = (formData) => {
    return async (dispatch) => {
        dispatch(fetchFaqDataRequest());
        try {
            const {data} = await api.faq(formData);
            dispatch(fetchFaqDataSuccess(data));
            return data;

        } catch (error) {
            dispatch(fetchFaqDataFailure);
        }
    };
};