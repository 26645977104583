import React, { useEffect } from 'react'
import { Box, List, ListItem, ListItemText, Card, CardMedia, CardContent, Typography, Rating, Grid, Pagination, ListItemButton, Divider, useMediaQuery, CircularProgress, ButtonGroup, Button, IconButton } from '@mui/material';
import canva from '../../assets/icons/canva.webp';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { getAllCardsByGroupNameAndGroupType } from '../../redux/actions/cardsForCustomerActions';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import 'animate.css';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';


function InvitationCardMaster() {


    const params = useParams();
    const [name, setName] = useState();
    console.log("Params", params);
    useEffect(() => {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
        window.scrollTo(0, 0);
    }, []);




    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const limit = 50;

    // useEffect(() => {
    //     console.log("params.group_path", params.group_path);
    //     // dispatch(getAllCardsByGroupNameAndGroupType(params.group_path, "pdf", page, limit));
    //     getAllCardsByGroupNameAndGroupType(params.group_path, "pdf", page, limit);
    // }, [page]);

    const handlePageChange = async (event, value) => {
        console.log("value", value);
        // await setPage(value);
        // let rsp = await dispatch(getAllCardsByGroupNameAndGroupType(params.group_path, "pdf", page, value));
    };
    const [loading, setLoading] = useState(true);
    const [invitationCards, setInvitationcards] = useState([]);
    const groupType = 'pdf'
    var cards = useSelector((state) => state?.cardforCustomer?.cardForCustomerdata?.data);
    const cards_original = useSelector((state) => state?.cardforCustomer?.cardForCustomerdata?.data);
    useEffect(() => {
        if (cards) {
            console.log("Cards", cards);
            setInvitationcards(cards.rows);
        }
    }, [cards]);

    useEffect(() => {
        const fetchCardForCustomer = async () => {
            setLoading(true);
            let rsp = await dispatch(getAllCardsByGroupNameAndGroupType(params.group_path, selectedButton, page, limit));
            console.log("RSP", rsp);
            let total = rsp?.data?.total;
            let cards = rsp?.data?.rows;
            setInvitationcards(cards);
            console.log("Invitation Cards", invitationCards);
            let pages = Math.ceil(total / Number(limit));
            if (pages) {
                setTotalPages(pages);
            }
            setLoading(false);
        };

        fetchCardForCustomer();
    }, [params.group_path, dispatch]);

    const handleNavigation = (card_id) => {
        navigate(`/cards/${params?.group_path}/${selectedButton}/${card_id}`);
    };

    // Animation
    const [hover, setHover] = useState(null);

    const handleMouseEnter = (index) => {
        setHover(index);
    };

    const handleMouseLeave = () => {
        setHover(null);
    };

    // button group for fetching data 
    const [selectedButton, setSelectedButton] = useState("pdf");
    const [SelectFreeButton, setSelectFreeButton] = useState("0");

    const handleGroupTypeButtonClick = async (value) => {
        setSelectedButton(value);
        setLoading(true);
        await dispatch(getAllCardsByGroupNameAndGroupType(params.group_path, value, page, limit));
        setLoading(false);
    }

    const handleFreeTypeButtonClick = async () => {
        console.log("step 1", SelectFreeButton);
        await setSelectFreeButton(prev => (prev === true ? false : true));
        console.log("step 2", SelectFreeButton);
        if(SelectFreeButton){
            console.log("inside if");
            const card = await cards_original?.rows?.filter((card) => card?.sale_price == 0);
            setInvitationcards(card);
        }
        else{
            console.log("else block", cards_original);
            const card = await cards_original?.rows?.filter((card) => card?.sale_price > 0);
            setInvitationcards(card);
        }
       
    }
    useEffect(() => {
        if (SelectFreeButton) {
            console.log("Price type", SelectFreeButton);
        }
    }, [SelectFreeButton])
    useEffect(() => {
        if (selectedButton) {
            console.log("Group type:", selectedButton);
            if (selectedButton === 'pdf') {
                // Specific condition action
                console.log("pdf");
            }
        }
    }, [selectedButton]);

    const [isPlaying, setIsPlaying] = useState(false);

    const [playingVideos, setPlayingVideos] = useState({});

    const handlePlayVideo = (index) => {
        setPlayingVideos((prevState) => ({
            ...prevState,
            [index]: true,
        }));
    };

    const [openCarouselSamples, setOpenCarouselSamples] = useState(null);
    return (
        <>
            <Grid item xs={12} sm={9} md={10} sx={{ padding: '32px' }}>
                <Typography variant="h5" sx={{ textAlign: 'left', fontWeight: 800 }}>
                    {params?.group_path?.charAt(0)?.toUpperCase() + params?.group_path?.slice(1)}
                </Typography>
                <Typography sx={{ marginBottom: '30px', textAlign: 'left' }}>
                    {selectedButton === "pdf" ? 'Invitation Cards' : 'Invitation Videos'}
                </Typography>

                <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
                    <Button
                        variant='outlined'
                        sx={{
                            color: selectedButton === "pdf" ? '#424242' : '#616161',
                            borderColor: selectedButton === "pdf" ? '#bdbdbd' : '#e0e0e0',
                            backgroundColor: selectedButton === "pdf" ? '#bdbdbd' : '#eeeeee',
                            borderRadius: 4
                        }}
                        onClick={() => handleGroupTypeButtonClick("pdf")}
                    >
                        pdf
                    </Button>

                    <Button
                        variant='outlined'
                        sx={{
                            color: selectedButton === "video" ? '#424242' : '#616161',
                            borderColor: selectedButton === "video" ? '#bdbdbd' : '#e0e0e0',
                            backgroundColor: selectedButton === "video" ? '#bdbdbd' : '#eeeeee',
                            borderRadius: 4
                        }}
                        onClick={() => handleGroupTypeButtonClick("video")}
                    >
                        Video
                    </Button>
                    <Button
                        variant='outlined'
                        sx={{
                            color: SelectFreeButton === "0" ? '#424242' : '#616161',
                            borderColor: SelectFreeButton === "0" ? '#bdbdbd' : '#e0e0e0',
                            backgroundColor: SelectFreeButton === "0" ? '#bdbdbd' : '#eeeeee',
                            borderRadius: 4
                        }}
                        onClick={handleFreeTypeButtonClick}
                    >
                        Free
                    </Button>
                </Box>





                {/* Show loader when loading */}
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {/* Cards Grid */}
                        <Grid container spacing={3}>
                            {invitationCards && invitationCards.length > 0 ? (
                                invitationCards.map((card, firstIndex) =>
                    
                                    card.card_samples && card.card_samples.length > 0 ? (
                                        
                                        <Grid item xs={12} sm={6} md={4} lg={3} key={firstIndex}>
                                            
                                            <Card
                                                onClick={() => handleNavigation(card.id)}
                                                onMouseEnter={() => handleMouseEnter(firstIndex)}
                                                onMouseLeave={handleMouseLeave}
                                                className={hover === firstIndex ? 'animate__animated animate__pulse' : ''}
                                                sx={{ maxWidth: '100%', margin: 'auto' }}
                                            >
                                                <div style={{ position: 'relative' }}>
                                                    {card?.card_samples?.map((type, i) => (

                                                        <>
                                                            
                                                            {type?.url.endsWith(".webp") || type?.url.endsWith(".jpg") || type?.url.endsWith(".png") &&
                                                                i === 0 ? (

                                                                <>
                                                                    <CardMedia
                                                                        component="img"
                                                                        height="280"
                                                                        image={type.url}
                                                                        alt={type.url}
                                                                        style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                                                                    />
                                                                    <CardContent>
                                                                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'flex-start', md: 'center' }, gap: 1, marginBottom: { md: 1 } }}>
                                                                            <Box
                                                                                bgcolor="#d32f2f"
                                                                                color="white"
                                                                                px={0.5}
                                                                                py={0.5}
                                                                                borderRadius="4px"
                                                                                display="inline-block"
                                                                                sx={{ fontSize: '0.725rem' }}
                                                                            >
                                                                                {Math.round(((card?.original_price - card?.sale_price) / card?.original_price) * 100)}% off
                                                                            </Box>
                                                                            <Typography sx={{ fontSize: '0.825rem', color: 'red', fontWeight: 'bold' }}>
                                                                                Dev Diwali Sale
                                                                            </Typography>
                                                                        </Box>

                                                                        <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5, flexDirection: { xs: 'column', md: 'row' } }}>
                                                                            <Typography variant="h6" color="textPrimary" fontWeight="bold" sx={{ fontSize: '1.25rem', lineHeight: 1 }}>
                                                                                {Math.floor(card.sale_price) === 0 ? 'Free' : `₹${Math.floor(card.sale_price)}`}
                                                                            </Typography>
                                                                            <Typography variant="body2" color="textSecondary" sx={{ textDecoration: 'line-through', fontSize: '0.875rem' }}>
                                                                                M.R.P: ₹{card?.original_price.toLocaleString()}
                                                                            </Typography>
                                                                        </Box>

                                                                        <Typography sx={{ fontSize: '1rem' }}>
                                                                            {card.card_name}
                                                                        </Typography>
                                                                        <Rating value={card.card_rating} readOnly />
                                                                    </CardContent>

                                                                    {/* Multi-Page button only if there are multiple samples */}
                                                                    {card?.card_samples?.length > 1 && (
                                                                        <Box
                                                                            sx={{
                                                                                position: 'absolute',
                                                                                top: '8px',
                                                                                right: '8px',
                                                                                backgroundColor: '#d32f2f',
                                                                                color: 'white',
                                                                                px: 0.5,
                                                                                py: 0.5,
                                                                                borderRadius: '4px',
                                                                                fontSize: '0.725rem',
                                                                                cursor: 'pointer'
                                                                            }}
                                                                            onClick={(event) => {
                                                                                event.stopPropagation();
                                                                                setOpenCarouselSamples(card.card_samples);

                                                                            }}
                                                                        >
                                                                            Multi-Page
                                                                        </Box>
                                                                    )}


                                                                </>
                                                            ) : null
                                                            }

                                                            {type?.url.endsWith(".mp4") &&
                                                                <>
                                                                    {!playingVideos[firstIndex] ? (
                                                                        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                                                                            <CardMedia
                                                                                component="img"
                                                                                image={card.card_samples[0].thumbnail}
                                                                                alt={card.card_name}
                                                                                style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                                                                            />
                                                                            <IconButton
                                                                                onClick={(event) => {
                                                                                    event.stopPropagation();
                                                                                    handlePlayVideo(firstIndex);
                                                                                }}
                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    top: '50%',
                                                                                    left: '50%',
                                                                                    transform: 'translate(-50%, -50%)',
                                                                                    color: '#616161',
                                                                                    fontSize: 64,
                                                                                }}
                                                                            >
                                                                                <PlayCircleOutlineIcon fontSize="inherit" />
                                                                            </IconButton>
                                                                        </div>
                                                                    ) : (
                                                                        <CardMedia
                                                                            component="video"
                                                                            controls
                                                                            autoPlay
                                                                            src={card.card_samples[0].url}
                                                                            alt={card.card_samples[0].thumbnail}
                                                                            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                                                                        />
                                                                    )}
                                                                    <CardContent>
                                                                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'flex-start', md: 'center' }, gap: 1, marginBottom: { md: 1 } }}>
                                                                            <Box
                                                                                bgcolor="#d32f2f"
                                                                                color="white"
                                                                                px={0.5}
                                                                                py={0.5}
                                                                                borderRadius="4px"
                                                                                display="inline-block"
                                                                                sx={{ fontSize: '0.725rem' }}
                                                                            >
                                                                                {Math.round(((card?.original_price - card?.sale_price) / card?.original_price) * 100)}% off
                                                                            </Box>
                                                                            <Typography sx={{ fontSize: '0.825rem', color: 'red', fontWeight: 'bold' }}>
                                                                                Dev Diwali Sale
                                                                            </Typography>
                                                                        </Box>

                                                                        <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5, flexDirection: { xs: 'column', md: 'row' } }}>
                                                                            <Typography variant="h6" color="textPrimary" fontWeight="bold" sx={{ fontSize: '1.25rem', lineHeight: 1 }}>
                                                                                {Math.floor(card.sale_price) === 0 ? 'Free' : `₹${Math.floor(card.sale_price)}`}
                                                                            </Typography>
                                                                            <Typography variant="body2" color="textSecondary" sx={{ textDecoration: 'line-through', fontSize: '0.875rem' }}>
                                                                                M.R.P: ₹{card?.original_price.toLocaleString()}
                                                                            </Typography>
                                                                        </Box>
                                                                        <Typography variant="h6" sx={{ fontWeight: 800, marginBottom: 1 }}>
                                                                            {card.card_name}
                                                                        </Typography>
                                                                        <Rating value={card.card_rating} readOnly />
                                                                    </CardContent>
                                                                </>
                                                            }

                                                        </>
                                                    ))}


                                                </div>

                                            </Card>
                                        </Grid>
                                    ) : (
                                        <Typography key={firstIndex}>Please wait while we are fetching card details...</Typography>
                                    )
                                )
                            ) : (
                                <Grid item xs={12}>
                                    <Typography variant="h3" align="center">No Data Available</Typography>
                                    <Typography variant="h6" align="center"> We are uploading data in this section</Typography>
                                </Grid>
                            )}
                        </Grid>


                        {/* Pagination */}
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
                            <Pagination
                                count={totalPages}
                                page={page}
                                onChange={handlePageChange}
                                color="primary"
                            />
                        </Box>
                    </>
                )}
            </Grid>
        </>
    )
}

export default InvitationCardMaster