import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";

export const getAllCardsDataRequest = () => ({
    type: actionTypes.GET_ALL_CARDS_DATA_REQUEST,
});
export const getAllCardsDataSuccess = (data) => ({
    type: actionTypes.GET_ALL_CARDS_DATA_SUCCESS,
    payload: data,
});

export const getAllCardsDataFailure = (error) => ({
    type: actionTypes.GET_ALL_CARDS_DATA_FAILURE,
    payload: error
});

export const getAllCardsData = (page, limit) => {
    return async(dispatch) => {
        dispatch(getAllCardsDataRequest());
        try{
            const {data} = await api.getAllCards(page, limit);
            dispatch(getAllCardsDataSuccess(data));
            return data;
        }catch(error) {
            dispatch(getAllCardsDataFailure(error));
        }
    }
}