import React, { useState } from 'react';
import { Box,List,ListItem,ListItemText,Grid,Divider,useMediaQuery,ListItemButton,IconButton,Drawer,} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Outlet, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu'; 

const categories = [
  { label: 'Dashboard', path: '/dashboard/complete-profile' },
  { label: 'My Profile', path: '/dashboard/my-profile' },
  { label: 'My Cards', path: '/dashboard/my-cards' },
  { label: 'Invoices', path: '/dashboard/invoices' },
  { label: 'Support Ticket', path: '/support-ticket' },
];

const Dashboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); 
  const isLaptopOrAbove = useMediaQuery(theme.breakpoints.up('md')); 
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); 
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false); 

  
  const handleMobileDrawerToggle = () => {
    setIsMobileSidebarOpen(!isMobileSidebarOpen);
  };

  
  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };


  const sidebarContent = (
    <List>
      {categories.map((category, index) => (
        <React.Fragment key={index}>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate(category.path)}>
              <ListItemText sx={{ color: '#616161' }} primary={category.label} />
            </ListItemButton>
          </ListItem>
          <Divider sx={{ borderStyle: 'dotted', borderColor: '#aaa', borderWidth: '0 0 1px 0' }} />
        </React.Fragment>
      ))}
    </List>
  );

  return (
    <Box sx={{ display: 'flex', overflowX: 'hidden', padding: 0, marginTop: 4 }}>
      {/* Mobile Layout */}
      {isMobile ? (
        <Grid container spacing={0} sx={{ marginX: 'auto', width: '100%', maxWidth: '100%' }}>
          <Grid
            item
            xs={12}
            sm={10}
            sx={{
              padding: '4px',
              minHeight: '100vh',
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleMobileDrawerToggle}
              sx={{ ml: 1, display: { sm: 'none' } }} 
            >
              <MenuIcon />
            </IconButton>

            {/* Mobile Sidebar Drawer */}
            <Drawer
              variant="temporary"
              anchor="left"
              open={isMobileSidebarOpen}
              onClose={handleMobileDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
              }}
            >
              {sidebarContent}
            </Drawer>

            <Outlet />
          </Grid>
        </Grid>
      ) : (
        // Laptop/Desktop Layout
        <Grid container spacing={0} sx={{ marginX: 'auto', width: '100%', maxWidth: '100%' }}>
          {/* Sidebar for laptop and larger */}
          {isLaptopOrAbove && (
            <Grid
              item
              xs={12}
              sm={isSidebarOpen ? 2 : 'auto'} 
              sx={{
                borderRadius: '10px',
                overflow: 'hidden',
                padding: '5px 0',
                backgroundColor: '#e0e0e0',
                height: '100vh',
                position: 'sticky',
                top: 0,
                display: isSidebarOpen ? 'block' : 'none', 
              }}
            >
              {sidebarContent}
            </Grid>
          )}

          {/* Main content area */}
          <Grid
            item
            xs={12}
            sm={isSidebarOpen ? 10 : 12}
            sx={{
              padding: '2px',
              marginRight: 0,
              minHeight: '100vh',
            }}
          >

            <Outlet />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Dashboard;
