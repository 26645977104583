import * as actionTypes from "../actionTypes.js";

const initialState ={
    faqdata: [],
    loading: false,
    error: null,
}

const faqReducer = (state= initialState, action) => {

    switch(action.type){
        case actionTypes.FETCH_FAQ_DATA_REQUEST: 
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.FETCH_FAQ_DATA_SUCCESS: 
            return{
                ...state,
                faqdata: action.payload,
                loading: false,
                error: null,
            };
        case actionTypes.FETCH_FAQ_DATA_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }

};

export default faqReducer;