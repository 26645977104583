import React from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import {
  Button,
  TextField,
  IconButton,
  Box,
  Typography,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';

function Form() {
  const { control, handleSubmit, register, setValue } = useForm({
    defaultValues: {
      users: [
        { name: 'Subrot', email: 'subrot@example.com' },
        { name: 'Vishal', email: 'vishal@example.com' },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users',
  });

  const onSubmit = (data) => {
    console.log('Updated Data:', data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {fields.map((item, index) => (
        <Box
          key={item.id}
          display="flex"
          alignItems="center"
          mb={2}
        >
          <TextField
            {...register(`users.${index}.name`)}
            defaultValue={item.name}
            onChange={(e) =>
              setValue(`users.${index}.name`, e.target.value)
            }
            label="Name"
            variant="outlined"
            margin="normal"
            sx={{ mr: 2 }}
          />
          <TextField
            {...register(`users.${index}.email`)}
            defaultValue={item.email}
            onChange={(e) =>
              setValue(`users.${index}.email`, e.target.value)
            }
            label="Email"
            variant="outlined"
            margin="normal"
            sx={{ mr: 2 }}
          />
          <IconButton
            onClick={() => remove(index)}
            color="secondary"
            aria-label="remove user"
          >
            <Delete />
          </IconButton>
        </Box>
      ))}

      <Box display="flex" justifyContent="space-between" mt={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={() => append({ name: '', email: '' })}
        >
          Add User
        </Button>
        <Button variant="contained" color="primary" type="submit">
          Submit
        </Button>
      </Box>
    </form>
  );
}

export default Form;
