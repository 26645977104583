import React, { useState, useEffect } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Button, Container, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

function FormSubmissionPage() {
    const navigate = useNavigate();
    const [timer, setTimer] = useState(30);
    const attemptId = useParams();
    console.log("params in success screen", attemptId?.bundle_id);
    useEffect(() => {
        if (timer === 0) {
            navigate(`/bundle-order/${attemptId?.bundle_id}`);
        }

        const countdown = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
        }, 1000 );

        return () => clearInterval(countdown);
    }, [timer, navigate]);

    return (
        <div>
            <Container maxWidth='sm' style={{ textAlign: 'center', marginTop: '50px' }}>
                <CheckCircleOutlineIcon style={{ fontSize: 80, color: 'green' }} />
                <Typography variant='h4' style={{ marginTop: '20px' }}>
                    E-invite Data Submitted Successfully
                </Typography>
               
              
                <Typography variant='body1' style={{ margin: '20px 0' }}>
                    Redirecting you to Download page in {timer} seconds...
                </Typography>
                <Button onClick={() => navigate(`/bundle-order/${attemptId?.bundle_id}`)} variant='contained' color='primary' sx={{ marginBottom: 5 }}>
                    Go to Download Page
                </Button>
            </Container>
        </div>
    )
}

export default FormSubmissionPage