import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export const getAllProfilesRequest = () => ({
    type: actionTypes.GET_ALL_PROFILES_REQUEST
});
export const getAllProfilesSuccess = (data) => ({
    type: actionTypes.GET_ALL_PROFILES_SUCCESS,
    payload: data
});
export const getAllProfilesFailure = (error) => ({
    type: actionTypes.GET_ALL_PROFILES_FAILURE,
    payload: error
});

export const getAllProfilesData = () => {
    return async(dispatch) => {
        dispatch(getAllProfilesRequest());
        try{
            const {data} = await api.getAllProfiles();
            console.log("data in multi profile action", data)
            dispatch(getAllProfilesSuccess(data));
            return data;
        }catch(error) {
            dispatch(getAllProfilesFailure(error));
        }
    };
};

export const getSingleProfileRequest = () => ({
    type: actionTypes.GET_SINGLE_PROFILE_DETAILS_REQUEST
});

export const getSingleProfileSuccess = (data) => ({
    type: actionTypes.GET_SINGLE_PROFILE_DETAILS_SUCCESS,
    payload: data
});

export const getSingleProfileFailure = (error) => ({
    type: actionTypes.GET_SINGLE_PROFILE_DETAILS_FAILURE,
    payload: error,
});

export const getSingleProfileData = (id) => {
    console.log("Id for Single Profile", id);
    return async(dispatch) => {
        dispatch(getSingleProfileRequest());
        try{
            const {data} = await api.getSingleProfile(id);
            console.log("Data for Single Profile", data);
            dispatch(getSingleProfileSuccess(data));
            return data;
        } catch (error) {
            dispatch(getAllProfilesFailure(error));
        }
    };
};

export const addProfileRequest = () => ({
    type: actionTypes.ADD_PROFILE_DETAILS_REQUEST,
});

export const addProfileSuccess = (data) => ({
    type: actionTypes.ADD_PROFILE_DETAILS_SUCCESS,
    payload: data
});

export const addProfileFailure = (error) => ({
    type: actionTypes.ADD_PROFILE_DETAILS_FAILURE,
    payload: error,
});

export const addProfileData = (formData) => {
    console.log("form data for create", formData)
    return async(dispatch) => {
        dispatch(addProfileRequest());
        try{
            const {data} = await api.createProfile(formData);
            console.log("data for creation", data);
            dispatch(addProfileSuccess(data));
            return data;
        } catch(error) {
            dispatch(addProfileFailure(error));
        }
    }
};

export const updateProfileDetailsRequest = () => ({
    type: actionTypes.UPDATE_PROFILE_DETAILS_REQUEST,
});

export const updateProfileDetailsSuccess = (data) => ({
    type: actionTypes.UPDATE_PROFILE_DETAILS_SUCCESS,
    payload: data,
});

export const updateProfileDetailsFailure = (error) => ({
    type: actionTypes.UPDATE_PROFILE_DETAILS_FAILURE,
    payload: error
});

export const updateProfileDetailsData = (formData) => {
    return async(dispatch) => {
        dispatch(updateProfileDetailsRequest());
        try{
            const response = await api.updateProfile(formData.profileId, formData);
            const successAction = response.data;
            dispatch(updateProfileDetailsSuccess(successAction));
            return successAction;
        }catch (error) {
            dispatch(updateProfileDetailsFailure(error));
        }
    };
};

export const deleteProfileRequest = () => ({
    type: actionTypes.DELETE_PROFILE_REQUEST,
});
export const deleteProfileSuccess = (id) => ({
    type: actionTypes.DELETE_PROFILE_SUCCESS,
    payload: id
});
export const deleteProfileFailure = (error) => ({
    type: actionTypes.DELETE_PROFILE_FAILURE,
    payload: error
});

export const deleteProfileData = (id) => {
    return async(dispatch) => {
        dispatch(deleteProfileRequest());
        try{

            const {data} = await api.deleteProfile(id.profileId);
            dispatch(deleteProfileSuccess(id.profileId));
            return data;

        }catch (error) {
            dispatch(deleteProfileFailure(error));

        }
    }
}