import React, { useState } from 'react';
import { Box, Paper, Typography, Button, List, ListItem, ListItemText } from '@mui/material';

const DragAndDropExample = () => {
  const [draggedItem, setDraggedItem] = useState(null);
  const [droppedItems, setDroppedItems] = useState([]);

  const items = [
    {
      id: 'item-1',
      text: 'Card 1',
      fontSize: '16px',
      color: 'green',
      width: '100px',
      height: '100px',
      positionX: '25px',
      positionY: '50px',
      zIndex: 1,
    },
    {
      id: 'item-2',
      text: 'Card 2',
      fontSize: '18px',
      color: 'blue',
      width: '100px',
      height: '100px',
      positionX: '30px',
      positionY: '60px',
      zIndex: 2,
    },
    {
      id: 'item-3',
      text: 'Card 3',
      width: '100px',
      height: '100px',
      positionX: '35px',
      positionY: '70px',
      zIndex: 3,
    },
  ];

  const handleDragStart = (event, item, isDropped) => {
    setDraggedItem({ ...item, isDropped }); // Track if the item is from the dropped area
  };

  const handleDragOver = (event) => {
    event.preventDefault(); // Allow dropping by preventing default behavior
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const rect = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - rect.left; // Calculate X coordinate relative to the drop area
    const y = event.clientY - rect.top; // Calculate Y coordinate relative to the drop area

    if (draggedItem && !draggedItem.isDropped) {
      // If the item is from the left side, add it to the dropped items list with position
      setDroppedItems((prev) => [
        ...prev,
        { ...draggedItem, positionX: `${x}px`, positionY: `${y}px` },
      ]);
    } else if (draggedItem && draggedItem.isDropped) {
      // If the item is already dropped, update its position
      setDroppedItems((prev) =>
        prev.map((item) =>
          item.id === draggedItem.id
            ? { ...item, positionX: `${x}px`, positionY: `${y}px` }
            : item
        )
      );
    }
    setDraggedItem(null); // Clear dragged item state
  };

  const handleDeleteDrop = (event) => {
    event.preventDefault();
    if (draggedItem) {
      // Remove item from the dropped items list
      setDroppedItems((prev) => prev.filter((item) => item.id !== draggedItem.id));
      setDraggedItem(null); // Clear dragged item state
    }
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      {/* Left Side: Draggable Items */}
      <Box
        sx={{
          width: '25%',
          padding: 2,
          borderRight: '1px solid #ddd',
          overflowY: 'auto',
        }}
      >
        {items.map((item) => (
          <Paper
            key={item.id}
            draggable
            onDragStart={(e) => handleDragStart(e, item, false)}
            sx={{
              padding: 2,
              marginBottom: 2,
              cursor: 'grab',
              textAlign: 'center',
              fontSize: item.fontSize,
              color: item.color,
              width: item.width,
              height: item.height,
            }}
          >
            <Typography variant="h6">{item.text}</Typography>
          </Paper>
        ))}
      </Box>

      {/* Right Side: Droppable Area */}
      <Box
        sx={{
          width: '75%',
          padding: 2,
          position: 'relative', // Important for absolute positioning of children
          border: '1px solid #ddd',
          backgroundColor: '#f4f4f4',
        }}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {/* Droppable Items with Free Floating Behavior */}
        {droppedItems.map((item) => (
          <Paper
            key={item.id}
            draggable
            onDragStart={(e) => handleDragStart(e, item, true)}
            sx={{
              position: 'absolute',
              top: item.positionY,
              left: item.positionX,
              fontSize: item.fontSize,
              color: item.color,
              width: item.width,
              height: item.height,
              padding: 2,
              backgroundColor: 'lightblue',
              cursor: 'grab',
              zIndex: item.zIndex,
              textAlign: 'center',
            }}
          >
            <Typography variant="h6">{item.text}</Typography>
          </Paper>
        ))}

        {/* Display Properties of Dropped Items */}
        <Box
          sx={{
            width: '100%',
            maxHeight: '20%',
            overflowY: 'auto',
            borderTop: '1px solid #ddd',
            padding: 1,
            position: 'absolute',
            bottom: 60, // Just above the delete button
            left: 0,
            backgroundColor: 'white',
          }}
        >
          {droppedItems.length === 0 ? (
            <Typography variant="body1" color="textSecondary">
              No properties to display
            </Typography>
          ) : (
            <List>
              {droppedItems.map((item) => (
                <ListItem key={item.id}>
                  <ListItemText
                    primary={`${item.text} Properties:`}
                    secondary={`Font Size: ${item.fontSize}, Color: ${item.color}, Width: ${item.width}, Height: ${item.height}, PositionX: ${item.positionX}, PositionY: ${item.positionY}, Z-Index: ${item.zIndex}`}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </Box>

        {/* Delete Button */}
        <Box
          sx={{
            width: '100%',
            textAlign: 'center',
            position: 'absolute',
            bottom: 10,
            left: 0,
            marginTop: 20
          }}
          onDragOver={handleDragOver}
          onDrop={handleDeleteDrop}
        >
          <Button variant="contained" color="error" fullWidth>
            Delete Item
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DragAndDropExample;