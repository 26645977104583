import { Box } from '@mui/material'
import React from 'react'

function RefundPolicy() {
  return (
    <>
    <Box sx={{marginLeft: 8 , marginRight: 8}}>
    <div class="container pt-5 content-header">
        <h1 class="pb-3 border-bottom">Refund Policy</h1>
        <div class="content-body">
          <div class="entry-content">
            <p>Refund policies are different depending on what you bought.</p>
            <p class="has-large-font-size">Digital Invitation Purchase</p>
            <p><strong>Within 48 hours &amp; Service Not Used: </strong>You may be
              able to get a refund depending on the details of the purchase.</p>
            <p><strong>Service Used: </strong>No Refund.</p>
            <p>* All deduction of payment gateway charges from all refunds.</p>
            <p class="has-large-font-size">Physical Invitation Purchase</p>
            <p><strong>Within 48 hours &amp; Printing Not Started: </strong>You may
              be able to get a refund of maximum 95% depending on the details of the purchase.</p>
            <p><strong>Within 12 hours &amp; Printing Not Started: </strong>You may
              be able to get a refund depending on the details of the purchase.</p>
            <p><strong>Printing Started/completed: </strong>You may be able to get a
              refund maximum 25% depending on the details of the purchase.</p>
            <p>* All deduction of payment gateway charges from all refunds.</p>

            <p><strong>Subscriptions: </strong>Subscription started can not be refunded for the current billing period. Your Subscription can be stopped from the next billing cycle.</p>

            <p><strong>Subscriptions (Stopped due to Govt/Court Guildelines or decision): </strong>Is non refundable except it is stated in court decision.</p>
          </div>
        </div>
      </div>
    </Box>
     
    </>
  )
}

export default RefundPolicy