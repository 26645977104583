import { Box } from '@mui/material'
import React from 'react'

function DeleteAccountPage() {
  return (
    <>
      <Box sx={{ marginLeft: 8, marginRight: 8 }}>
        <div class="container pt-5 content-header">
          <h1 class="pb-3 border-bottom">Please write email to
            support@awesomeinnovators.com</h1>

        </div>
      </Box>

    </>
  )
}

export default DeleteAccountPage