import React, { useEffect, useState } from 'react'
import { Box, List, ListItem, ListItemText, Card, CardMedia, CardContent, Typography, Rating, Grid, Pagination, ListItemButton, Divider, useMediaQuery, Stack, Button, CardActions, IconButton, Container, Menu, MenuItem } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAllCardsData } from '../../redux/actions/allCardsDataActions';
import 'animate.css';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTheme as useAppTheme } from '../../theme/ThemeContext';

const MyCards = () => {
    const cardData = useSelector((state) => state?.cardData?.cardsData?.data);
    console.log("Card Data", cardData);

    const { isDarkMode, toggleTheme } = useAppTheme();

    // fetch Data

    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const limit = 8;

    const [loading, setLoading] = useState();

    useEffect(() => {
        const fetchCardsData = async () => {
            let rsp = await dispatch(getAllCardsData(page, limit));
            let total = rsp?.data?.total;
            let pages = Math.ceil(total / Number(limit));
            setTotalPages(pages);
            setLoading(false);
        }
        fetchCardsData();
    }, [dispatch])

    {/* useEffect(() => {
        getCardEntryData(page, limit)
    }, [page]);

    const handlePageChange = async (event, value) => {
        console.log("value", value);
        await setPage(value);
        let rsp = await dispatch(getCardEntryData(value, limit));
    };
    

    useEffect(() => {
        const getCardEntriesData = async () => {
            setLoading(true);
            const rsp = await dispatch(getCardEntryData(page, limit));
            let total = rsp?.data?.total;
            let pages = Math.ceil(total / Number(limit));
            setTotalPages(pages);
            setLoading(false);
        };
        getCardEntriesData();
    }, [dispatch]); */}

    // Animation 
    const [hover, setHover] = useState(null);

    const handleMouseEnter = (index) => {
        setHover(index);
    };

    const handleMouseLeave = () => {
        setHover(null);
    };
    // menu items 
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    // Handle menu open/close
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>

            <Typography className='animate__animated animate__slideInRight animate__delay-0.5s' variant="h5" sx={{ marginBottom: '16px', textAlign: 'left', fontWeight: 800, marginLeft: 1.5 }}>
                My Cards
            </Typography>
            <Grid
                container
                spacing={2}
                style={{ marginTop: "2px", padding: 5 }}
            >
                {cardData?.rows?.length > 0 ? (
                    cardData.rows.map((card, index) => (
                        <Grid item xs={12} md={6} key={index}>
                            <Card sx={{ backgroundColor: '#f8f4ff', }}>
                                <CardContent>
                                    <Grid item xs={12} md={12} lg={12} key={index}>

                                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                            {/* Content and Button Box */}
                                            <Grid item xs={8} >

                                                <Typography variant="body2" color="textSecondary">
                                                    #{card?.id?.slice(0, 8)} ({card?.entry_associated_card?.group_detail.group_name})
                                                </Typography>

                                                <Typography variant="h6" gutterBottom>
                                                    {card?.title}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary" paragraph>
                                                    {card?.entry_associated_card?.card_description}
                                                </Typography>
                                                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: { xs: '10px', sm: 0 } }}>
                                                    <Typography
                                                        sx={{
                                                            backgroundColor: '#fdd835',
                                                            color: 'black',
                                                            borderRadius: '15px',
                                                            padding: '5px 15px',
                                                            fontWeight: 'bold',

                                                        }}
                                                    >
                                                        {card?.payment_status === 'Pending' ? 'Payment pending' : 'Your Card is Ready'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={3.5} >

                                                <CardMedia
                                                    component="img"
                                                    sx={{ height: '180px', objectFit: 'contain' }}
                                                    image={card?.thumbnail}
                                                    alt={card?.thumbnail}
                                                />


                                            </Grid>
                                            <Grid item xs={0.5}  >
                                                <IconButton onClick={handleMenuClick}>
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                    anchorEl={anchorEl}
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleMenuClose}
                                                >
                                                    <MenuItem>Edit</MenuItem>
                                                    <MenuItem>Delete</MenuItem>

                                                </Menu>
                                            </Grid>

                                        </Box>


                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} key={index} sx={{ padding: 1 }}>
                                        {/* <Box sx={{ marginTop: 0, display: 'flex', justifyContent: 'space-around' }}>
                                                <Button variant="outlined" sx={{ borderRadius: 5, color: 'black' }}>
                                                    Edit Card
                                                </Button>
                                                <Button variant="contained" sx={{ backgroundColor: 'green', borderRadius: 5 }}>
                                                    Pay Now
                                                </Button>
                                        </Box> */}

                                        <Box sx={{ marginTop: 0, display: 'flex', justifyContent: 'space-around' }}>
                                            {card?.editCounter > 0 && (
                                                <Button
                                                    sx={{
                                                        backgroundColor: '#fdd835',
                                                        color: 'black',
                                                        borderRadius: '15px',
                                                        padding: '5px 15px',
                                                        fontWeight: 'bold',
                                                        '&:hover': { backgroundColor: '#fbc02d' },
                                                        textAlign: 'center',
                                                        marginRight: '10px',
                                                    }}
                                                >
                                                    Edit Card
                                                </Button>
                                            )}

                                            {card?.payment_status == 'Pending' && (
                                                <Button
                                                    sx={{
                                                        backgroundColor: 'pink',
                                                        color: 'black',
                                                        borderRadius: '15px',
                                                        padding: '5px 15px',
                                                        fontWeight: 'bold',
                                                        '&:hover': { backgroundColor: '#fbc02d' },
                                                        textAlign: 'center',
                                                        marginRight: '10px',
                                                    }}
                                                >
                                                    Pay Now
                                                </Button>
                                            )}

                                            {card?.final_url && (
                                                <Button
                                                    sx={{
                                                        backgroundColor: isDarkMode ? '#1E1E1E' : '#b71c1c',
                                                        color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'white',
                                                        borderRadius: '15px',
                                                        padding: '5px 15px',
                                                        fontWeight: 'bold',
                                                        '&:hover': { backgroundColor: '#fbc02d' },
                                                        textAlign: 'center',
                                                    }}
                                                    onClick={() => {
                                                        const link = document.createElement('a');
                                                        link.href = card.final_url;
                                                        link.target = '_blank';
                                                        link.download = 'file.pdf'; // Optional: specify a default filename
                                                        document.body.appendChild(link);
                                                        link.click();
                                                        document.body.removeChild(link);
                                                    }}
                                                >
                                                    Download Now
                                                </Button>
                                            )}
                                        </Box>
                                    </Grid>
                                </CardContent>


                            </Card>
                        </Grid>
                    ))
                ) : (
                    <Grid item xs={12}>
                        <Typography sx={{ marginTop: 5, marginLeft: 5 }}>No Product available</Typography>
                    </Grid>
                )}
            </Grid>



            {/* Pagination */}
            {/* <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 5, }}>
                <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                    size= "small"
                />
            </Box>*/}
        </>
    )
}

export default MyCards