import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Typography, Grid, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { fetchFaqData } from '../../redux/actions/faqActions';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const FAQ = () => {
  const [open, setOpen] = useState(false);
  const [selectedFAQ, setSelectedFAQ] = useState({ question: '', answer: '' });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const handleOpenDialog = (faq) => {
    setSelectedFAQ(faq);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const faqs = useSelector((state) => state.faq.faqdata);
  

  useEffect(() => {
    const fetchFaqsData = async () => {
      setLoading(true);
      const response = await dispatch(fetchFaqData());
      console.log("response", response);
    };
    fetchFaqsData();
  }, [dispatch])
  

  return (
    <Box sx={{  py: 8 }}>
      <Container>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 'bold',
            color: '#D9376E',
            mb: 4,
            textAlign: 'center',
          }}
        >
          FAQ.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            textAlign: 'center',
            mb: 4,
          }}
        >
          At InOut, we strive to bring together developers to participate in the hackathon irrespective of gender, race, sexual orientation, socioeconomic background, or ethnicity. We usually try to keep things simple. Read our FAQs to phase out ambiguity.
        </Typography>
        <Grid container spacing={3}>
          {faqItems.map((item, index) => (
            <Grid item xs={12} md={6} key={index}>
              
                <Accordion 
                   sx={{
                    border: '1px solid #FFD700',
                    borderRadius: 1,
                    mb: 2,
                    backgroundColor: '#fff',
                    cursor: 'pointer',
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>{item.question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                      malesuada lacus ex, sit amet blandit leo lobortis eget.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
            </Grid>
          ))}
        </Grid>

        {/* Dialog Component */}
        
      </Container>
    </Box>
  );
};

const faqItems = [
  { question: 'Who can participate?', answer: 'Everyone is welcome to participate.' },
  { question: 'When will applications close?', answer: 'Applications will close on the 15th of next month.' },

];

export default FAQ;